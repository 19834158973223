import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useCoverTypeOptions } from '../hooks/useCoverTypeOptions';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { CoverOptions, OptionCode } from '@/api/retail/v2';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { COMPREHENSIVE_COVER_TYPE_CODE, NewMtaFormSchema } from '@/features/NewMta/hooks';

type NewMtaCoverDetailsProps = {
  coverOptions: CoverOptions;
  noClaimsDiscountYears: OptionCode[];
};

export function NewMtaCoverDetails({
  coverOptions,
  noClaimsDiscountYears,
}: NewMtaCoverDetailsProps) {
  const { watch } = useFormContext<NewMtaFormSchema>();
  const { coverTypeOptions, shouldDisableCoverTypeDropdown } = useCoverTypeOptions();
  const coverType = watch('cover.type');

  const formattedVoluntaryExcessAmounts =
    coverOptions.voluntaryExcessAmounts?.map(amount => ({
      code: amount,
      description: formatCurrency(amount),
    })) ?? [];

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Cover
      </Typography>
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        select
        label="Cover type"
        name="cover.type"
        id="coverType"
        disabled={shouldDisableCoverTypeDropdown}
      >
        {coverTypeOptions}
      </FormTextField>
      {coverType === COMPREHENSIVE_COVER_TYPE_CODE && (
        <FormTextField
          sx={{ ...commonSx, ...maxWidthSx }}
          select
          label="Voluntary excess amount"
          name="cover.voluntaryExcessAmount"
          id="voluntaryExcessAmount"
        >
          {renderSelectOptionsByOptionsCodes(formattedVoluntaryExcessAmounts)}
        </FormTextField>
      )}
      <FormTextField
        label="Years of NCB"
        name="cover.yearsOfNCB"
        select
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(noClaimsDiscountYears ?? [])}
      </FormTextField>
      <FormRadioGroup
        name="cover.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled"
        label="Has any driver ever had insurance declined or cancelled?"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label="Yes" value={YesNo.Yes} />
        <FormRadio label="No" value={YesNo.No} />
      </FormRadioGroup>
    </>
  );
}
