import { z } from 'zod';
import { validateConvictionOrClaimDate } from '../../helpers/newMtaFormHelper';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { YesNo } from '@/models/YesNo';

export const convictionFormSchema = z
  .object({
    _isExisting: z.boolean(),
    type: z
      .object({ description: z.string(), code: z.string() })
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    date: z
      .date()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    penaltyPoints: z
      .string()
      .refine(value => Boolean(value), validationMessages.required)
      .refine(value => Number(value) >= 0, validationMessages.minValue(0))
      .refine(value => Number(value) <= 36, validationMessages.maxValue(36)),
    fineAmount: z
      .string()
      .refine(value => Boolean(value), validationMessages.required)
      .refine(value => Number(value) >= 1, validationMessages.minValue(1))
      .refine(value => Number(value) <= 10000000, validationMessages.maxValue(10000000)),
    banReceived: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    disqualifiedLengthInMonths: z.string(),
  })
  .refine(
    ({ banReceived, disqualifiedLengthInMonths }) =>
      banReceived === YesNo.No || disqualifiedLengthInMonths !== '',
    {
      message: validationMessages.required,
      path: ['disqualifiedLengthInMonths'],
    },
  )
  .refine(
    ({ banReceived, disqualifiedLengthInMonths }) =>
      banReceived === YesNo.No || Number(disqualifiedLengthInMonths) >= 1,
    {
      message: validationMessages.minValue(1),
      path: ['disqualifiedLengthInMonths'],
    },
  )
  .refine(
    ({ banReceived, disqualifiedLengthInMonths }) =>
      banReceived === YesNo.No || Number(disqualifiedLengthInMonths) <= 120,
    {
      message: validationMessages.maxValue(120),
      path: ['disqualifiedLengthInMonths'],
    },
  )
  .superRefine(validateConvictionOrClaimDate);

export type ConvictionFormSchema = z.input<typeof convictionFormSchema>;
