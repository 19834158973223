import { useMutation } from '@tanstack/react-query';
import { env } from '@/config/env';

type GenerateLetterRequestBody = {
  policyId: string;
  policyholderFirstName: string;
  policyholderLastName: string;
  address: {
    line1: string;
    line2?: string;
    line3?: string;
    line4?: string;
    line5?: string;
    postCode: string;
  };
};

const ENDPOINT_URL = `${env.config.DOCUMENT_CREATION_API_URL}/generate-letter-template`;

export function useGenerateLetterMutation() {
  const generateLetter = (body: GenerateLetterRequestBody) =>
    fetch(ENDPOINT_URL, { method: 'POST', body: JSON.stringify(body) })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        return res.blob();
      })
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });

  return useMutation({
    mutationFn: generateLetter,
  });
}
