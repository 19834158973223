import { Box, Button, Divider, Grid, List, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { StyledButtonsContainer } from '../Notes.styles';
import { NoteDetailsAttachment } from './NoteDetailsAttachment';
import { NoteDeleteModal } from './NoteDeleteModal';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { Note } from '@/api/note';
import {
  getCommunicationMethodName,
  getNoteInitiatorName,
} from '@/helpers/nameMappings/noteNameMappings';
import { useUser } from '@/hooks/useUser';
import { useDeleteNoteMutation } from '@/hooks/mutations/useDeleteNoteMutation';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { NoteInitiator } from '@/api/support';

type NoteDetailsProps = {
  note: Note;
  onEdit: (note: Note) => void;
};

export function NoteDetails({ note, onEdit }: NoteDetailsProps) {
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<Note | null>();
  const { setAlert } = useSnackbarAlert();
  const { isUserId, hasPermissions } = useUser();
  const isSystemNote = note.initiator === NoteInitiator.SYSTEM;
  const canDeleteNote = !isSystemNote && hasPermissions(['delete:note']);
  const canEditNote =
    !isSystemNote &&
    ((hasPermissions(['update:note']) && note.createdBy && isUserId(note.createdBy)) ||
      hasPermissions(['update:note', 'updateotherusers:note']));

  const {
    mutate: deleteNote,
    isPending: isDeleteNotePending,
    isError: isDeleteNoteError,
  } = useDeleteNoteMutation({
    onSuccess: () => {
      setNoteToBeDeleted(null);
      setAlert({ message: 'Note deleted', severity: 'success' });
    },
  });

  const handleDeleteNote = () => {
    if (noteToBeDeleted) {
      deleteNote({
        policyId: note.parentId!,
        noteId: note.id,
      });
    }
  };

  return (
    <Box position="relative">
      <Divider />
      {(canEditNote || canDeleteNote) && (
        <StyledButtonsContainer>
          {canEditNote && (
            <Button size="small" onClick={() => onEdit(note)}>
              Edit
            </Button>
          )}
          {canDeleteNote && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setNoteToBeDeleted(note)}
              sx={{ marginLeft: 2 }}
            >
              Delete
            </Button>
          )}
        </StyledButtonsContainer>
      )}
      <List>
        <ListRow label="Initiator">{getNoteInitiatorName(note.initiator)}</ListRow>
        <ListRow label="Communication method">
          {getCommunicationMethodName(note.communicationMethod)}
        </ListRow>
        <ListRow label="Summary">{note.summary}</ListRow>
        <ListRow label="Description">{note.description}</ListRow>
        <ListRow label="Created on">{formatDateTime(note.createdAtUtc)}</ListRow>
        <ListRow label="Created by">{note.createdByName}</ListRow>
        {note.modifiedAtUtc && (
          <ListRow label="Modified on">{formatDateTime(note.modifiedAtUtc)}</ListRow>
        )}
        {note.modifiedBy && <ListRow label="Modified by">{note.modifiedBy}</ListRow>}
      </List>
      {note.attachments.length > 0 && (
        <>
          <Divider />
          <Grid container spacing={3} py={6}>
            <Grid item xs={5}>
              <Typography variant="subtitle2">Attachments</Typography>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="column" spacing={6}>
                {note.attachments.map(attachment => (
                  <NoteDetailsAttachment key={attachment.id} attachment={attachment} />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      <Divider />
      <Typography pt={6} pb={3} variant="subtitle2">
        Reminder
      </Typography>
      <ListRow label="Due date/time">
        {note.reminder?.dateOfReminder && formatDateTime(note.reminder.dateOfReminder)}
      </ListRow>
      <ListRow label="Assigned team">{note.reminder?.assigneeTeam}</ListRow>{' '}
      {Boolean(noteToBeDeleted) && (
        <NoteDeleteModal
          isError={isDeleteNoteError}
          isPending={isDeleteNotePending}
          onConfirm={handleDeleteNote}
          onCancel={() => setNoteToBeDeleted(null)}
        />
      )}
    </Box>
  );
}
