import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { DebtReason } from '@/api/support';
import { DebtType } from '@/api/motorpolicy';

const addNewFeeFormSchema = z.object({
  feeExcludingTax: z
    .string()
    .refine(value => Boolean(value), validationMessages.required)
    .refine(value => Number(value) >= 0, validationMessages.minValue(0))
    .refine(value => Number(value) <= 1000000, validationMessages.maxValue(1000000)),
  reason: z
    .nativeEnum(DebtReason)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  type: z
    .nativeEnum(DebtType)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  description: z
    .string()
    .min(5, validationMessages.minLength(5))
    .max(500, validationMessages.maxLength(500)),
});

export type AddNewFeeFormSchema = z.input<typeof addNewFeeFormSchema>;

export function useAddNewFeeForm() {
  return useForm<AddNewFeeFormSchema>({
    defaultValues: {
      feeExcludingTax: '',
      reason: null,
      type: null,
      description: '',
    },
    resolver: zodResolver(addNewFeeFormSchema),
  });
}
