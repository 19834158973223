import heic2any from 'heic2any';

export function downloadFile(name: string, content: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = content;
  downloadLink.download = name;
  downloadLink.click();
}

export function viewBase64Document(base64: string) {
  const win = window.open();
  win?.document.write(
    `<style>
      body { margin: 0; padding: 0; }
      iframe { width: 100%; height: 100%; border: none; margin: 0; padding: 0; }
    </style>
    <iframe src="${base64}" frameborder="0"></iframe>`,
  );
}

const convertHeicToPng = async (file: Blob): Promise<Blob> => {
  return file.type === 'image/heic' ? ((await heic2any({ blob: file })) as Blob) : file;
};

export async function readFileContent(file: Blob): Promise<string | null> {
  const convertedFile = await convertHeicToPng(file);

  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => {
      const content = event.target?.result?.toString() ?? null;
      resolve(content);
    };
    reader.readAsDataURL(convertedFile);
  });
}

export function getFileNameFromUrl(url: string) {
  const fileName = url.split('/').reverse()[0];
  const tokenRegex = /\.[^?]*\?/;
  return tokenRegex.test(fileName) ? fileName.split('?')[0] : fileName;
}
