import DescriptionIcon from '@mui/icons-material/Description';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useLocation, useNavigate } from 'react-router';
import {
  StyledNavbarTab,
  StyledNavbarTabs,
} from '@/components/ui/styles/StyledNavbarTabs';
import { RoutePath } from '@/models/RoutePath';

export function SearchTabs() {
  const location = useLocation();
  const navigate = useNavigate();
  const value = location.pathname.includes(RoutePath.Quotes)
    ? RoutePath.Quotes
    : RoutePath.Policies;

  const handleChange = (_event: React.SyntheticEvent, path: RoutePath) => {
    navigate(`/search/${path}`);
  };

  return (
    <StyledNavbarTabs value={value} onChange={handleChange}>
      <StyledNavbarTab
        value={RoutePath.Policies}
        label="Policies"
        icon={<DescriptionIcon fontSize="small" />}
        iconPosition="start"
      />
      <StyledNavbarTab
        style={{ display: 'none' }}
        value={RoutePath.Quotes}
        label="Quotes"
        icon={<CalculateIcon fontSize="small" />}
        iconPosition="start"
      />
    </StyledNavbarTabs>
  );
}
