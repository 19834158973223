import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { addMinutes, isBefore } from 'date-fns';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { ActionRequired } from '@/api/vulnerabilities';
import { YesNo } from '@/models/YesNo';
import { VulnerabilityCategory, VulnerabilityType } from '@/api/support';
import { getDate, resetSecondsAndMilliseconds } from '@/helpers/utils/dateHelpers';

export const getMinVulnerabilityFollowUpDate = () => {
  return addMinutes(resetSecondsAndMilliseconds(getDate()), 1);
};

const vulnerabilitiesFormSchema = z
  .object({
    _id: z.string(),
    _noteId: z.string().nullable().optional(),
    temporary: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    consentObtained: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    category: z
      .nativeEnum(VulnerabilityCategory)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    type: z
      .nativeEnum(VulnerabilityType)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    action: z
      .nativeEnum(ActionRequired)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    description: z
      .string()
      .min(5, validationMessages.minLength(5))
      .max(500, validationMessages.maxLength(500)),
    followUpRequired: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    followUpDate: z.nullable(z.date()),
    resolved: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    comment: z
      .string()
      .min(5, validationMessages.minLength(5))
      .max(500, validationMessages.maxLength(500))
      .optional()
      .or(z.literal('')),
  })
  .superRefine(({ followUpRequired, temporary, followUpDate }, ctx) => {
    if (followUpRequired === YesNo.Yes || temporary === YesNo.Yes) {
      if (!followUpDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: validationMessages.required,
          path: ['followUpDate'],
        });
      }

      if (followUpDate && isBefore(followUpDate, getMinVulnerabilityFollowUpDate())) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: validationMessages.minDateTime(getMinVulnerabilityFollowUpDate()),
          path: ['followUpDate'],
        });
      }
    }

    if (temporary === YesNo.Yes && followUpRequired === YesNo.No) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Temporary vulnerabilities must have Follow Up Required set to true.',
        path: ['followUpRequired'],
      });
    }
  });

export type VulnerabilitiesFormSchema = z.input<typeof vulnerabilitiesFormSchema>;

export type VulnerabilityFormDefaultValues = Partial<VulnerabilitiesFormSchema> & {
  _id: string;
};

export function useVulnerabilitiesForm(defaultValues?: VulnerabilityFormDefaultValues) {
  return useForm<VulnerabilitiesFormSchema>({
    defaultValues: {
      _id: '',
      temporary: null,
      consentObtained: null,
      category: null,
      type: null,
      action: null,
      description: '',
      followUpRequired: null,
      followUpDate: null,
      resolved: null,
      comment: '',
      ...defaultValues,
    },
    resolver: zodResolver(vulnerabilitiesFormSchema),
  });
}
