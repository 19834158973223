import { useContext, useMemo } from 'react';
import { Alert } from '@mui/material';
import { HistoryDetails } from './components/HistoryDetails';
import { HistoryHeader } from './components/HistoryHeader';
import { HistoryContext } from './hooks/useHistoryContext';
import { HistoryReversalQuoteCalculation } from './components/HistoryReversal/components/HistoryReversalQuoteCalculation';
import { useHistoryReversalQuote } from './hooks/useHistoryReversalQuote';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { AccordionList, AccordionListItem } from '@/components/ui/lists/AccordionList';
import { StyledDrawerContent } from '@/components/ui/styles/StyledDrawerContent';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { sortByDateDesc } from '@/helpers/utils/arrayHelpers';
import { getApiErrorMessage } from '@/helpers/utils/errorHelpers';
import { useSetAlertError } from '@/hooks/useSetAlertError';

export function History() {
  const policyId = useContext(PolicyIdContext);
  const { isPending, isError, data: policy } = useMotorPolicyQuery(policyId);
  const {
    isQuoteFetching,
    isQuoteError,
    shouldShowQuote,
    quoteError,
    reversalQuote,
    calculateReversalQuote,
    resetReversalQuote,
  } = useHistoryReversalQuote();

  const transactions = policy?.transactions ?? [];

  const items: AccordionListItem[] = useMemo(
    () =>
      sortByDateDesc(transactions, 'createdDateTimeUtc').map((transaction, index) => ({
        key: index,
        header: (
          <HistoryHeader
            transaction={transaction}
            transactionNo={transactions.length - index}
          />
        ),
        content: transaction.policyVersion != null && (
          <HistoryDetails transaction={transaction} />
        ),
      })),
    [transactions],
  );

  useSetAlertError(isQuoteError, getApiErrorMessage(quoteError));

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  if (!items.length) {
    return (
      <StyledDrawerContent>
        <Alert severity="info">This policy does not have any history</Alert>
      </StyledDrawerContent>
    );
  }

  if (shouldShowQuote) {
    return (
      <HistoryReversalQuoteCalculation
        quote={reversalQuote!}
        onClearCalculation={resetReversalQuote}
      />
    );
  }

  return (
    <HistoryContext.Provider value={{ isQuoteFetching, calculateReversalQuote }}>
      <AccordionList items={items} />
    </HistoryContext.Provider>
  );
}
