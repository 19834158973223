import { useFormContext } from 'react-hook-form';
import { getExcludedCoverTypeOptionCodes } from '../helpers/getAvailableCoverTypeOptions';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { renderSelectOptionsByOptionsCodes } from '@/helpers/utils/formHelpers';
import { coverOptions } from '@/mocks/data/coverOptions.data';

export function useCoverTypeOptions() {
  const {
    formState: { defaultValues },
  } = useFormContext<NewMtaFormSchema>();
  const initialCoverType = defaultValues?.cover?.type;
  const excludedCoverTypeOptionCodes = initialCoverType
    ? getExcludedCoverTypeOptionCodes(initialCoverType)
    : [];
  const coverTypeOptions = renderSelectOptionsByOptionsCodes(
    coverOptions.coverTypes ?? [],
    {
      excludeCodes: excludedCoverTypeOptionCodes,
    },
  );

  return {
    coverTypeOptions,
    shouldDisableCoverTypeDropdown: coverTypeOptions.length <= 1,
  };
}
