import { NewMtaFormSchema } from '../../hooks/schemas/newMta.schema';
import { mapPolicyToNewMtaDriversForm } from './mapPolicyToNewMtaDriversForm';
import { mapPolicyToNewMtaCarForm } from './mapPolicyToNewMtaCarForm';
import { mapCoverToNewMtaCoverForm } from './mapCoverToNewMtaCoverForm';
import { MotorPolicy } from '@/api/motorpolicy';

export function mapPolicyToNewMtaForm(policy: MotorPolicy): NewMtaFormSchema {
  const policyMainDriverIndex = policy.drivers.findIndex(
    ({ isMainDriver }) => !!isMainDriver,
  );

  return {
    _policy: policy,
    _isQuoteLoaded: false,
    startDate: null,
    mainDriverIndex: policyMainDriverIndex,
    drivers: mapPolicyToNewMtaDriversForm(policy.drivers, policy.policyholder),
    car: mapPolicyToNewMtaCarForm(policy.vehicle, policy.policyholder),
    cover: mapCoverToNewMtaCoverForm(policy),
  };
}
