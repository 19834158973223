import { createContext, Dispatch, SetStateAction, useContext } from 'react';

type ActionsContextProps = {
  setIsPolicyActionVisible: Dispatch<SetStateAction<boolean>>;
};

export const ActionsContext = createContext<ActionsContextProps | undefined>(undefined);

export function useActionsContext() {
  const context = useContext(ActionsContext);

  if (context === undefined) {
    throw new Error('useActionsContext must be used within ActionsContext');
  }

  return context;
}
