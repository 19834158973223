import { LoadingButton } from '@mui/lab';
import { useHistoryContext } from '../../hooks/useHistoryContext';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

type HistoryReversalProps = { transactionId: string };

export function HistoryReversal({ transactionId }: HistoryReversalProps) {
  const { isQuoteFetching, calculateReversalQuote } = useHistoryContext();

  return (
    <StyledButtonsFooter sticky sx={{ py: 6, px: 0 }}>
      <LoadingButton
        loading={isQuoteFetching}
        onClick={() => calculateReversalQuote(transactionId)}
        sx={{ alignSelf: 'flex-start' }}
      >
        Calculate reversal cost
      </LoadingButton>
    </StyledButtonsFooter>
  );
}
