import { useSelectedPaymentPlan } from '../../../hooks/useSelectedPaymentPlan';
import { isMonthlyPaymentPlan } from '../../../helpers/quoteCalculationHelper';
import { useQuoteCalculationContext } from '../../../hooks/useQuoteCalculationContext';
import { QuoteCalculationMakeNonPaymentOrder } from './QuoteCalculationMakeNonPaymentOrder';
import { QuoteCalculationMakePaymentOrder } from './QuoteCalculationMakePaymentOrder';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { useNavigateToOverview } from '@/hooks/useNavigateToOverview';

type QuoteCalculationMakeOrderProps = {
  includeSelectedPaymentPlanInNonPaymentRequest: boolean;
  successMsg: string;
  resetPrompt: () => void;
};

export function QuoteCalculationMakeOrder({
  includeSelectedPaymentPlanInNonPaymentRequest,
  successMsg,
  resetPrompt,
}: QuoteCalculationMakeOrderProps) {
  const { setAlert } = useSnackbarAlert({ closeOnUnmount: false });
  const { quoteResult } = useQuoteCalculationContext();
  const selectedPaymentPlan = useSelectedPaymentPlan();
  const navigateToOverview = useNavigateToOverview();
  const isNonPaymentOrder =
    quoteResult === 'refund' ||
    quoteResult === 'no_charge_or_refund' ||
    isMonthlyPaymentPlan(selectedPaymentPlan!);

  const showAlertAndNavigate = () => {
    setAlert({
      message: successMsg,
      severity: 'success',
    });
    navigateToOverview();
    resetPrompt();
  };

  return isNonPaymentOrder ? (
    <QuoteCalculationMakeNonPaymentOrder
      includeSelectedPaymentPlanInNonPaymentRequest={
        includeSelectedPaymentPlanInNonPaymentRequest
      }
      onSuccess={showAlertAndNavigate}
    />
  ) : (
    <QuoteCalculationMakePaymentOrder
      onCancel={resetPrompt}
      onSuccess={showAlertAndNavigate}
    />
  );
}
