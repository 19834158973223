import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { mapAccountDefaultValues } from '../helpers/mapAccountDefaultValues';
import { Account } from '@/api/support';
import { YesNo } from '@/models/YesNo';
import { validationMessages } from '@/helpers/utils/textHelpers';
import {
  isValidLandlineNumber,
  isValidPhoneNumber,
} from '@/helpers/validations/phoneValidations';

const accountFormSchema = z
  .object({
    customerId: z.string(),
    customerIdentityProvider: z.string(),
    email: z.string(),
    landline: z
      .string()
      .refine(value => isValidLandlineNumber(value), {
        message: 'Enter valid telephone number',
      })
      .or(z.literal('')),
    mobile: z
      .string()
      .refine(value => isValidPhoneNumber(value), {
        message: 'Enter valid mobile number',
      })
      .or(z.literal('')),
    allowedToMarketByEmail: z.boolean(),
    allowedToMarketBySMS: z.boolean(),
    allowedToMarketByPhone: z.boolean(),
    allowedToMarketByPost: z.boolean(),
    autoRenewPolicy: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
  })
  .refine(({ landline, mobile }) => Boolean(landline) || Boolean(mobile), {
    message: 'At least one phone number needs to be set',
    path: ['mobile'],
  });

export type AccountFormSchema = z.input<typeof accountFormSchema>;

export function useAccountForm(account: Account | undefined) {
  return useForm<AccountFormSchema>({
    defaultValues: mapAccountDefaultValues(account),
    resolver: zodResolver(accountFormSchema),
  });
}
