import { Driver } from '@/api/motorpolicy';

const STUDENT_STATUS_CODE = 'F';

export function getIsStudent(driver: Driver) {
  return driver.occupations.some(
    occupation => occupation.employmentStatus?.code === STUDENT_STATUS_CODE,
  );
}

export function getHasSecondaryJob(driver: Driver) {
  return driver.occupations.length > 1;
}
