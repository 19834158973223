import { CoverOptions } from '@/api/retail/v2';

export const coverOptions: CoverOptions = {
  coverTypes: [
    {
      code: 'COMP',
      description: 'Comprehensive',
      type: 'comprehensive',
    },
    {
      code: 'TPFT',
      description: 'Third party, fire and theft',
      type: 'third_party_fire_and_theft',
    },
    {
      code: 'TPO',
      description: 'Third party only',
      type: 'third_party',
    },
  ],
  voluntaryExcessAmounts: [0, 100, 150, 250, 350, 500, 750, 1000],
};
