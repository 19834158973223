import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useEffect } from 'react';
import {
  CancelPolicyFormSchema,
  useCancelPolicyForm,
} from '../../hooks/useCancelPolicyForm';
import { useActionsContext } from '../../hooks/useActionsContext';
import { CancelPolicyQuoteCalculation } from './components/CancelPolicyQuoteCalculation';
import { CancelPolicyFormFields } from './components/CancelPolicyFormFields';
import { CancelPolicyFormFooter } from './components/CancelPolicyFormFooter';
import { useCancellationQuote } from './hooks/useCancellationQuote';
import { MotorPolicy } from '@/api/motorpolicy';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { getDateWithOffset } from '@/helpers/utils/dateHelpers';
import { useSetAlertError } from '@/hooks/useSetAlertError';

export function CancelPolicyForm({ policy }: { policy: MotorPolicy }) {
  const { setIsPolicyActionVisible } = useActionsContext();
  const form = useCancelPolicyForm(policy);
  const {
    quote,
    cancelResponse,
    isError: isQuoteError,
    isPending: isQuotePending,
    mutate: requestCancellationQuote,
    reset: resetCancellationQuote,
  } = useCancellationQuote();

  const isQuoteLoaded = Boolean(quote?.id);
  const { watch, handleSubmit } = form;
  const effectiveDateTime = watch('effectiveDateTime');

  const handleValidSubmit: SubmitHandler<CancelPolicyFormSchema> = async ({
    effectiveDateTime,
    cancellationReason,
    description,
  }) => {
    requestCancellationQuote({
      effectiveDateTimeUtc: getDateWithOffset(effectiveDateTime!),
      cancellationReason: cancellationReason ?? undefined,
      cancellationDescription: description ?? '',
    });
  };

  useSetAlertError(isQuoteError);

  useEffect(() => {
    setIsPolicyActionVisible(!isQuoteLoaded);
  }, [isQuoteLoaded]);

  if (isQuoteLoaded && quote && cancelResponse) {
    return (
      <CancelPolicyQuoteCalculation
        policy={policy}
        quote={quote}
        cancelResponse={cancelResponse}
        effectiveDateTime={effectiveDateTime!}
        onClearCalculation={resetCancellationQuote}
      />
    );
  }

  return (
    <FormProvider {...form}>
      <StyledDrawerForm
        sx={{ height: '100%' }}
        onSubmit={handleSubmit(handleValidSubmit)}
      >
        <CancelPolicyFormFields />
        <CancelPolicyFormFooter isPending={isQuotePending} />
      </StyledDrawerForm>
    </FormProvider>
  );
}
