import { Divider, List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { ReferenceData } from '@/api/motorpolicy';
import { YesNo } from '@/models/YesNo';

type MedicalConditionsContentProps = {
  medicalCondition: ReferenceData | undefined;
};

export function MedicalConditionsContent({
  medicalCondition,
}: MedicalConditionsContentProps) {
  const hasMedicalCondition = medicalCondition && medicalCondition?.code !== 'N';

  return (
    <>
      <Divider />
      <List>
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
          Medical conditions
        </Typography>
        <ListRow label="Medical conditions">
          {hasMedicalCondition ? YesNo.Yes : YesNo.No}
        </ListRow>
        {hasMedicalCondition && (
          <ListRow label="Condition">{medicalCondition?.description}</ListRow>
        )}
      </List>
    </>
  );
}
