import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { RouterProvider } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'react-oidc-context';
import { enGB } from 'date-fns/locale';
import { theme } from './config/theme';
import { router } from './config/router';
import { queryClient } from './config/react-query';
import { ApiClientProvider } from './contexts/ApiClientContext';
import { GlobalStyles } from './config/styles';
import { oidcConfig } from './config/oidc';
import { SnackbarAlertContextProvider } from './components/ui/alerts/SnackbarAlertContextProvider';

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <CssBaseline />
        <GlobalStyles />
        <AuthProvider {...oidcConfig}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ApiClientProvider>
              <SnackbarAlertContextProvider>
                <RouterProvider router={router} />
              </SnackbarAlertContextProvider>
            </ApiClientProvider>
          </QueryClientProvider>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
