import { ApiError } from '@/api/core/ApiError';

export const GENERAL_ERROR_MSG = 'Something went wrong. Please try again later on.';

const isApiError = (error: unknown): error is ApiError => !!(error as ApiError)?.body;

const getFirstErrorMessage = (error: ApiError) => {
  if (typeof error.body !== 'object') {
    return;
  }

  const [firstError] = Object.values(error.body);

  if (Array.isArray(firstError)) {
    const [firstErrorMessage] = firstError;

    if (typeof firstErrorMessage === 'string') {
      return firstErrorMessage;
    }
  }
};

export const getApiErrorMessage = (
  error: ApiError | unknown,
  errorCodeMessages?: Record<string, string>,
): string => {
  if (!isApiError(error) || error.status === 500) {
    return GENERAL_ERROR_MSG;
  }

  if (typeof error.body === 'string' && error.body.trim().length > 0) {
    return error.body;
  }

  if (typeof error.body === 'object') {
    const matchedErrorCode = Object.keys(error.body).find(
      code => errorCodeMessages?.[code],
    );

    if (!errorCodeMessages || !matchedErrorCode) {
      return getFirstErrorMessage(error) ?? GENERAL_ERROR_MSG;
    }

    if (matchedErrorCode) {
      return errorCodeMessages[matchedErrorCode];
    }
  }

  return GENERAL_ERROR_MSG;
};
