import { NewMtaEndorsementsModal } from './components/NewMtaEndorsementsModal';
import { NewMtaConfirmationModal } from './components/NewMtaConfirmationModal';
import { NewMtaExcessesModal } from './components/NewMtaExcessesModal';
import { useExcesses } from './hooks/useExcesses';
import { NewMtaQuoteCalculationCostSummaryItems } from './components/NewMtaQuoteCalculationCostSummaryItems';
import { NewMtaQuoteCalculationPriceBreakdown } from './components/NewMtaQuoteCalculationPriceBreakdown';
import { MotorQuote } from '@/api/motorquote';
import { CreateMtaResponse } from '@/api/motorpolicy';
import { FeeType } from '@/api/motorquote';
import { QuoteResult } from '@/helpers/data/quoteHelpers';
import { selectTotalPriceIncAddOns } from '@/helpers/selectors/quoteSelectors';
import { QuoteCalculation } from '@/features/PaymentActions/QuoteCalculation/QuoteCalculation';
import { Endorsement } from '@/api/retail/v2';

export type NewMtaQuoteCalculationProps = {
  quote: CreateMtaResponse & MotorQuote;
  endorsements: Endorsement[];
  onClearCalculation: () => void;
};

const alertCopyByQuoteResult: Record<QuoteResult, string> = {
  charge: 'Making these changes means the cost of the policy will increase',
  refund: 'Making these changes means the cost of the policy will decrease',
  no_charge_or_refund:
    'Making these changes means the cost of the policy will remain the unchanged',
};

export function NewMtaQuoteCalculation({
  quote,
  endorsements,
  onClearCalculation,
}: NewMtaQuoteCalculationProps) {
  const totalPriceIncAddOns = selectTotalPriceIncAddOns(quote);
  const { updatedExcesses } = useExcesses(quote);

  if (totalPriceIncAddOns === null) {
    return <QuoteCalculation.Error />;
  }

  return (
    <QuoteCalculation quote={quote} feeType={FeeType.MTAFEE} total={totalPriceIncAddOns}>
      <QuoteCalculation.Alert alertCopyByQuoteResult={alertCopyByQuoteResult} />
      <QuoteCalculation.CostSummary label="Cost for changes">
        <NewMtaQuoteCalculationCostSummaryItems />
      </QuoteCalculation.CostSummary>
      <QuoteCalculation.SecondaryActions>
        <QuoteCalculation.PriceBreakdown>
          <NewMtaQuoteCalculationPriceBreakdown quote={quote} />
        </QuoteCalculation.PriceBreakdown>
        <QuoteCalculation.UpdateFeesPremiumAction />
      </QuoteCalculation.SecondaryActions>
      <QuoteCalculation.PaymentPlans />
      <QuoteCalculation.PrimaryActions>
        <QuoteCalculation.CreateOrderAction
          label="Process MTA"
          successMsg="Mid-term adjustment has been successful. New documents are being generated and will be emailed shortly."
          includeSelectedPaymentPlanInNonPaymentRequest
        >
          {updatedExcesses.length > 0 && (
            <QuoteCalculation.CreateOrderAction.Prompt>
              <NewMtaExcessesModal excesses={updatedExcesses} />
            </QuoteCalculation.CreateOrderAction.Prompt>
          )}
          {endorsements.length > 0 && (
            <QuoteCalculation.CreateOrderAction.Prompt>
              <NewMtaEndorsementsModal endorsements={endorsements} />
            </QuoteCalculation.CreateOrderAction.Prompt>
          )}
          <QuoteCalculation.CreateOrderAction.Prompt>
            <NewMtaConfirmationModal />
          </QuoteCalculation.CreateOrderAction.Prompt>
        </QuoteCalculation.CreateOrderAction>
        <QuoteCalculation.ClearCalculationAction
          onClearCalculation={onClearCalculation}
        />
      </QuoteCalculation.PrimaryActions>
    </QuoteCalculation>
  );
}
