import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useVehicleOwner,
  useVehicleOptions,
  NewMtaFormSchema,
} from '@/features/NewMta/hooks';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { isCarUsedInBusiness } from '@/helpers/data/carHelpers';
import { FormRadio } from '@/components/ui/forms/FormRadio';

export function NewMtaCarUsage() {
  const {
    isError: isVehicleOptionsError,
    isPending: isVehicleOptionsPending,
    data: vehicleOptions,
  } = useVehicleOptions();
  const {
    isError: isVehicleOwnerError,
    isPending: isVehicleOwnerPending,
    data: vehicleOwners,
  } = useVehicleOwner();
  const { watch } = useFormContext<NewMtaFormSchema>();
  const carUsage = watch('car.usage');
  const keptAtHomeOvernight = watch('car.keptAtHomeOvernight');

  if (isVehicleOptionsError || isVehicleOwnerError) return <DrawerError />;
  if (isVehicleOptionsPending || isVehicleOwnerPending) return <DrawerLoading />;

  return (
    <section aria-label="Car usage">
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Car usage
      </Typography>
      <FormTextField
        select
        name="car.usage"
        label="Usage"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(vehicleOptions.vehicleUsages ?? [])}
      </FormTextField>
      <FormNumberField
        name="car.personalMiles"
        label="Personal miles"
        sx={{ ...commonSx, ...maxWidthSx }}
        integerOnly
      />
      {isCarUsedInBusiness(carUsage) && (
        <FormNumberField
          name="car.businessMiles"
          label="Business miles"
          sx={{ ...commonSx, ...maxWidthSx }}
          integerOnly
          testId="new-mta_car-business-miles-numberbox"
        />
      )}
      <FormRadioGroup
        name="car.keptAtHomeOvernight"
        label="Kept at home overnight"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      {keptAtHomeOvernight === YesNo.No && (
        <FormTextField
          name="car.postCode"
          label="Postcode"
          sx={{ ...commonSx, ...maxWidthSx }}
        />
      )}
      <FormTextField
        select
        name="car.keptDuringDay"
        label="Kept during the day"
        sx={{ ...commonSx, ...maxWidthSx }}
        testId="new-mta_car-kept-during-day-selectbox"
      >
        {renderSelectOptionsByOptionsCodes(vehicleOptions.vehicleKeptLocations ?? [])}
      </FormTextField>
      <FormTextField
        select
        name="car.keptAtNight"
        label="Kept at night"
        sx={{ ...commonSx, ...maxWidthSx }}
        testId="new-mta_car-kept-at-night-selectbox"
      >
        {renderSelectOptionsByOptionsCodes(vehicleOptions.vehicleKeptLocations ?? [])}
      </FormTextField>
      <FormTextField
        select
        name="car.legalOwner"
        label="Legal owner"
        sx={{ ...commonSx, ...maxWidthSx }}
        testId="new-mta_car-legal-owner-selectbox"
      >
        {renderSelectOptionsByOptionsCodes(vehicleOwners ?? [])}
      </FormTextField>
      <FormTextField
        select
        name="car.registeredKeeper"
        label="Registered keeper"
        sx={{ ...commonSx, ...maxWidthSx }}
        testId="new-mta_car-registered-keeper-selectbox"
      >
        {renderSelectOptionsByOptionsCodes(vehicleOwners ?? [])}
      </FormTextField>
    </section>
  );
}
