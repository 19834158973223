import { createContext, useContext } from 'react';

type HistoryContextProps = {
  isQuoteFetching: boolean;
  calculateReversalQuote: (transactionId: string) => void;
};

export const HistoryContext = createContext<HistoryContextProps | undefined>(undefined);

export function useHistoryContext() {
  const context = useContext(HistoryContext);

  if (context === undefined) {
    throw new Error('useHistoryContext must be used within HistoryContext');
  }

  return context;
}
