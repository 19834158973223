import { useQuery } from '@tanstack/react-query';
import { useFetchFile } from '../useFetchFile';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { readFileContent } from '@/helpers/utils/fileHelpers';
import { env } from '@/config/env';

type UseNoteAttachmentQueryProps = {
  filePath: string;
  enabled?: boolean;
};

export function useNoteAttachmentQuery({
  filePath,
  enabled = true,
}: UseNoteAttachmentQueryProps) {
  const { fetchFile } = useFetchFile();

  const fetchNoteAttachment = async (filePath: string): Promise<string | null> => {
    const blob = await fetchFile(
      `${env.config.NOTE_API_URL}/api/v1/notes/attachments/${filePath}`,
    );
    return readFileContent(blob);
  };

  return useQuery({
    queryKey: queryKeys.noteAttachment(filePath),
    queryFn: () => fetchNoteAttachment(filePath),
    enabled,
  });
}
