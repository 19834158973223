import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { usePolicyholderAddressSearch } from './hooks/usePolicyholderAddressSearch';
import { usePolicyholderAddressSelect } from './hooks/usePolicyholderAddressSelect';
import { FormTextField, uppercase } from '@/components/ui/forms/FormTextField';
import {
  NewMtaFormSchema,
  PolicyholderDriverSchema,
  useNewMtaDriverContext,
} from '@/features/NewMta/hooks';
import {
  commonSx,
  getErrorProps,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';

type PolicyholderHomeAddressSearchProps = {
  onSelect: () => void;
  onEnterAddressManually: () => void;
};

export function PolicyholderHomeAddressSearch({
  onSelect,
  onEnterAddressManually,
}: PolicyholderHomeAddressSearchProps) {
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext<NewMtaFormSchema>();
  const { nestIndex, getFieldName } = useNewMtaDriverContext();
  const queryPostCodeForSearch = watch(getFieldName('_queryPostCodeForSearch'));
  const postCodeForSearch = watch(getFieldName('postCodeForSearch'));

  const {
    addressOptions,
    isAddressSearchError,
    isAddressSearchFetching,
    isAddressSearchPending,
    validateAndFindAddress,
  } = usePolicyholderAddressSearch();

  const { isAddressSelectFetching, isAddressSelectError, selectAddress } =
    usePolicyholderAddressSelect({ onSelect });

  const isAddressSearchDisabled = postCodeForSearch === queryPostCodeForSearch;
  const isManualEntryDisabled = !isAddressSearchDisabled;
  const isAddressSelectDisabled = !queryPostCodeForSearch;
  const shouldShowEnterAddressManually =
    isAddressSearchError ||
    isAddressSelectError ||
    (!isAddressSearchPending && !addressOptions.length);
  const shouldShowAddressSelect =
    isAddressSearchDisabled && !!addressOptions.length && !isAddressSearchError;

  const getPolicyholderErrors = () =>
    errors.drivers?.[nestIndex] as FieldErrorsImpl<PolicyholderDriverSchema> | undefined;

  const getPostCodeErrorProps = () => {
    const policyholderErrors = getPolicyholderErrors();

    if (postCodeForSearch !== queryPostCodeForSearch) return {};

    if (policyholderErrors?.postCodeForSearch) {
      return getErrorProps(policyholderErrors.postCodeForSearch);
    }

    if (policyholderErrors?._queryPostCodeForSearch) {
      return getErrorProps(policyholderErrors._queryPostCodeForSearch);
    }

    if (isAddressSearchError || isAddressSelectError) {
      return getErrorProps({
        message: 'Please check this',
        type: '',
      });
    }

    return {};
  };

  useEffect(() => {
    if (getPolicyholderErrors()?._queryPostCodeForSearch) {
      trigger(getFieldName('_queryPostCodeForSearch'));
    }
  }, [postCodeForSearch]);

  return (
    <Stack direction="column" gap={4}>
      <Stack direction="row" gap={4}>
        <FormTextField
          fullWidth
          sx={{ maxWidth: '240px' }}
          label="Home postcode"
          transformers={[uppercase]}
          name={getFieldName('postCodeForSearch')}
          {...getPostCodeErrorProps()}
        />
        <LoadingButton
          disabled={isAddressSearchDisabled}
          sx={{ alignSelf: 'baseline' }}
          loading={isAddressSearchFetching}
          onClick={validateAndFindAddress}
        >
          Find address
        </LoadingButton>
      </Stack>
      {shouldShowAddressSelect ? (
        <FormTextField
          sx={{ ...commonSx, ...maxWidthSx }}
          select
          loading={isAddressSearchFetching || isAddressSelectFetching}
          name={getFieldName('postOfficeAddressPostKey')}
          label="Address"
          disabled={isAddressSelectDisabled}
          onChange={event => selectAddress(event.target.value)}
        >
          {renderSelectOptionsByOptionsCodes(addressOptions)}
        </FormTextField>
      ) : null}
      {shouldShowEnterAddressManually ? (
        <Button
          variant="contained"
          size="small"
          sx={{ alignSelf: 'flex-start' }}
          onClick={onEnterAddressManually}
          disabled={isManualEntryDisabled}
        >
          Enter address manually
        </Button>
      ) : null}
    </Stack>
  );
}
