import { z } from 'zod';
import { driversSharedFields } from './newMtaDriversSharedFields.schema';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { YesNo } from '@/models/YesNo';
import { postcodeRegex } from '@/helpers/constants/regex';
import { PostOfficeAddress } from '@/api/motorpolicy';
import { zDiscriminatedUnion } from '@/helpers/utils/zodHelpers';

const additionalDriver = z.object({
  _isPolicyholder: z.literal(false),
  relationshipToPolicyholder: z
    .string()
    .refine(value => Boolean(value), validationMessages.required),
});

export const additionalDriverSchema = z.intersection(
  additionalDriver,
  driversSharedFields,
);

export type AdditionalDriverSchema = z.input<typeof additionalDriverSchema>;

const policyholderDriver = z
  .object({
    _isPolicyholder: z.literal(true),
    _fullAddress: z.string(),
    postCodeForSearch: z
      .string()
      .regex(postcodeRegex, 'Enter valid UK postcode')
      .max(10, validationMessages.maxLength(10))
      .refine(value => Boolean(value), validationMessages.required),
    _queryPostCodeForSearch: z.string(),
    postOfficeAddressPostKey: z.string().optional(),
    postOfficeAddress: z.custom<PostOfficeAddress>(),
    formattedAddress: z
      .object({
        line1: z
          .string()
          .max(50, validationMessages.maxLength(50))
          .refine(value => Boolean(value), validationMessages.required),
        line2: z.string().max(50, validationMessages.maxLength(50)).optional(),
        line3: z.string().max(50, validationMessages.maxLength(50)).optional(),
        line4: z.string().max(50, validationMessages.maxLength(50)).optional(),
        line5: z.string().max(50, validationMessages.maxLength(50)).optional(),
        postCode: z
          .string()
          .regex(postcodeRegex, 'Enter valid UK postcode')
          .max(10, validationMessages.maxLength(10))
          .optional(),
      })
      .optional(),
    isHomeOwner: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    numberOfVehiclesInHousehold: z
      .string()
      .refine(value => Boolean(value), validationMessages.required)
      .refine(value => Number(value) >= 0, validationMessages.minValue(0))
      .refine(value => Number(value) <= 15, validationMessages.maxValue(15)),
    childrenUnder16InHousehold: z
      .string()
      .refine(value => Boolean(value), validationMessages.required)
      .refine(value => Number(value) >= 0, validationMessages.minValue(0))
      .refine(value => Number(value) <= 15, validationMessages.maxValue(15)),
  })
  .refine(
    ({ postCodeForSearch, _queryPostCodeForSearch }) =>
      postCodeForSearch === _queryPostCodeForSearch,
    {
      message: 'Please confirm your changes by clicking the button',
      path: ['_queryPostCodeForSearch'],
    },
  )
  .refine(
    ({
      postOfficeAddress,
      _queryPostCodeForSearch,
      postOfficeAddressPostKey,
      formattedAddress,
    }) => {
      const postCodeForSearchWithoutWhitespace = _queryPostCodeForSearch.replaceAll(
        ' ',
        '',
      );
      const postOfficePostCodeWithoutWhitespace = (
        postOfficeAddress.postCode ?? ''
      ).replaceAll(' ', '');

      return (
        postCodeForSearchWithoutWhitespace === postOfficePostCodeWithoutWhitespace ||
        Boolean(postOfficeAddressPostKey) ||
        Boolean(formattedAddress?.postCode)
      );
    },
    {
      message: validationMessages.required,
      path: ['postOfficeAddressPostKey'],
    },
  );

const _policyholderDriverSchema = z.intersection(policyholderDriver, driversSharedFields);

export type PolicyholderDriverSchema = z.input<typeof _policyholderDriverSchema>;

const driversUnion = zDiscriminatedUnion('_isPolicyholder', [
  policyholderDriver,
  additionalDriver,
]);

const driver = z.intersection(driversUnion, driversSharedFields);

export const newMtaDriversFormSchema = z.array(driver);
