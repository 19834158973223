import { useSearchParams } from 'react-router';
import { NewMtaTab } from '@/features/NewMta';
import { NotesTab } from '@/features/Notes';
import { PolicyMenuItemKey, PolicyQueryParams } from '@/features/Policies/policyTypes';

const getDefaultTab = (key: PolicyMenuItemKey) => {
  switch (key) {
    case PolicyMenuItemKey.Notes:
      return NotesTab.List;
    case PolicyMenuItemKey.Drivers:
      return 'driver-0-tab';
    case PolicyMenuItemKey.NewMta:
      return NewMtaTab.Drivers;
    default:
      return null;
  }
};

export function usePolicyDrawerParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get(PolicyQueryParams.Tab) ?? '';
  const selectedSection =
    (searchParams.get(PolicyQueryParams.Section) as PolicyMenuItemKey) ??
    PolicyMenuItemKey.Overview;

  const getParams = (key: PolicyMenuItemKey) =>
    new URLSearchParams({
      ...Object.fromEntries(searchParams),
      [PolicyQueryParams.Section]: key,
    });

  const setDefaultTabParam = (key: PolicyMenuItemKey, params: URLSearchParams) => {
    params.delete(PolicyQueryParams.Tab);
    const tab = getDefaultTab(key);
    if (tab) params.set(PolicyQueryParams.Tab, tab);
  };

  const updateSectionParam = (key: PolicyMenuItemKey) => {
    const newParams = getParams(key);
    setDefaultTabParam(key, newParams);
    setSearchParams(newParams);
  };

  return {
    selectedTab,
    selectedSection,
    updateSectionParam,
  };
}
