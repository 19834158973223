import { z } from 'zod';
import { validationMessages } from '@/helpers/utils/textHelpers';

export const qualificationFormSchema = z.object({
  type: z
    .string()
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  date: z.date().nullable(),
});

export type QualificationFormSchema = z.input<typeof qualificationFormSchema>;
