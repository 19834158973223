import { Tab, TabProps } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useFormContext } from 'react-hook-form';
import { TabList } from '@mui/lab';
import { NewMtaTab } from '../newMtaTypes';
import { NewMtaFormSchema } from '../hooks';
import { useNewMtaTabContext } from '../hooks/useNewMtaTabContext';

export function NewMtaTabs() {
  const { currentTab, setCurrentTab } = useNewMtaTabContext();
  const {
    watch,
    formState: { errors },
  } = useFormContext<NewMtaFormSchema>();

  const isQuoteLoaded = watch('_isQuoteLoaded');

  const getTabErrorProps = (
    tab: NewMtaTab,
    errors: object | undefined,
  ): TabProps | Record<string, never> => {
    return currentTab !== tab && errors
      ? {
          icon: <CircleIcon color="error" sx={{ fontSize: '8px' }} />,
          iconPosition: 'start',
          sx: {
            minHeight: '48px',
          },
        }
      : {};
  };

  if (isQuoteLoaded) return null;

  return (
    <TabList onChange={(_event, value) => setCurrentTab(value)}>
      <Tab
        value={NewMtaTab.Drivers}
        label="Drivers"
        {...getTabErrorProps(NewMtaTab.Drivers, errors.drivers)}
      />
      <Tab
        value={NewMtaTab.Car}
        label="Car"
        {...getTabErrorProps(NewMtaTab.Car, errors.car)}
      />
      <Tab
        value={NewMtaTab.Cover}
        label="Cover"
        {...getTabErrorProps(NewMtaTab.Cover, errors.cover)}
      />
    </TabList>
  );
}
