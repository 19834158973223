import { useContext } from 'react';
import { Divider, Typography } from '@mui/material';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { selectSetupFee } from '@/helpers/selectors/quoteSelectors';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function CancelPolicyQuoteCalculationPriceBreakdownSetupFee() {
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const setupFee = policy && selectSetupFee(policy);

  if (!setupFee) return null;

  return (
    <>
      <Divider />
      <Typography
        variant="body2"
        sx={{
          color: theme => theme.palette.grey[500],
        }}
      >
        Includes a non-refundable set-up fee of {formatCurrency(setupFee)}
      </Typography>
    </>
  );
}
