import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import { parse } from 'date-fns';
import { BillingDetailsModal } from './BillingDetailsModal';
import { Billing } from '@/api/support';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { getTransactionStatusName } from '@/helpers/nameMappings/transactionNameMappings';

type PaymentsBillingProps = {
  billings: Billing[];
};

export function PaymentsBilling({ billings }: PaymentsBillingProps) {
  const [selectedBilling, setSelectedBilling] = useState<Billing | null>(null);

  return (
    <>
      <Table aria-label="billing table">
        <TableHead>
          <TableRow>
            <TableCell>Due date</TableCell>
            <TableCell>Collection date</TableCell>
            <TableCell>Collection method</TableCell>
            <TableCell>Collection amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billings.map((billing, index) => (
            <TableRow
              hover
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
              selected={Boolean(selectedBilling === billing)}
              onClick={() => setSelectedBilling(billing)}
            >
              <TableCell>
                {billing.dueDate &&
                  formatDate(parse(billing.dueDate, 'yyyy-MM-dd', new Date()))}
              </TableCell>
              <TableCell>
                {billing.collectionDate &&
                  formatDate(parse(billing.collectionDate, 'yyyy-MM-dd', new Date()))}
              </TableCell>
              <TableCell>{billing.collectionMethod}</TableCell>
              <TableCell>
                {billing.collectionAmount != null
                  ? formatCurrency(billing.collectionAmount)
                  : null}
              </TableCell>
              <TableCell>
                {billing.paymentStatus && getTransactionStatusName(billing.paymentStatus)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedBilling && (
        <BillingDetailsModal
          billing={selectedBilling}
          onClose={() => setSelectedBilling(null)}
        />
      )}
    </>
  );
}
