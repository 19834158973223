import { useAuth } from 'react-oidc-context';
import { z } from 'zod';

const authTokenPayloadSchema = z
  .object({
    permissions: z.string(),
    person_id: z.string(),
  })
  .transform(({ permissions, person_id }) => ({
    permissions,
    personId: person_id,
  }));

export type AuthTokenPayload = z.output<typeof authTokenPayloadSchema>;

export function useAuthTokenPayload() {
  const auth = useAuth();

  const getAuthTokenPayload = (): AuthTokenPayload | null => {
    const encodedPayload = auth.user?.access_token?.split('.')[1];

    if (!encodedPayload) return null;

    const decodedPayload = JSON.parse(atob(encodedPayload));
    const parseResult = authTokenPayloadSchema.safeParse(decodedPayload);
    return parseResult.success ? parseResult.data : null;
  };

  return getAuthTokenPayload();
}
