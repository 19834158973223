import { Outlet } from 'react-router';
import { Presentation } from './features/Navigation/Presentation/Presentation';
import { Navbar, SideNav, PageContainer } from '@/features/Navigation';

export function Root() {
  return (
    <>
      <Navbar />
      <aside>
        <SideNav />
      </aside>
      <main>
        <PageContainer maxWidth="xl" disableGutters>
          <Outlet />
          <Presentation />
        </PageContainer>
      </main>
    </>
  );
}
