import { Alert, Box, Button, List, Stack } from '@mui/material';
import { StyledVulnerabilityHeader } from '../VulnerabilitiesContent/VulnerabilitiesContent.styles';
import {
  getVulnerabilityActionName,
  getVulnerabilityTypeName,
} from '../VulnerabilityForm/helpers/vulnerabilityNameHelper';
import { VulnerabilitiesListItem } from './VulnerabilitiesList.styles';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { CustomerVulnerability } from '@/api/vulnerabilities';
import { useUser } from '@/hooks/useUser';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

type VulnerabilitiesListProps = {
  items: CustomerVulnerability[];
  onAddNewVulnerability: () => void;
  onEditVulnerability: (vulnerability: CustomerVulnerability, index: number) => void;
  onDeleteVulnerability: (vulnerability: CustomerVulnerability) => void;
};

export function VulnerabilitiesList({
  items,
  onAddNewVulnerability,
  onEditVulnerability,
  onDeleteVulnerability,
}: VulnerabilitiesListProps) {
  const { hasPermission } = useUser();

  return (
    <>
      <Box m={6}>
        {items.length ? (
          <List>
            {items.map((data, index) => {
              const {
                isTemporary,
                consentObtained,
                category,
                type,
                actionRequired,
                createdAtUtc,
                createdBy,
                description,
                followUpRequired,
                followUpDate,
                resolved,
                resolvedAtUtc,
                resolvedBy,
                comments,
              } = data;

              const headerText = `Vulnerability #${index + 1}`;

              return (
                <VulnerabilitiesListItem key={createdAtUtc} aria-label={headerText}>
                  <StyledVulnerabilityHeader>
                    {headerText}
                    <Stack gap={3}>
                      {hasPermission('updateany:vulnerablecustomer') && (
                        <Button
                          size="small"
                          onClick={() => onEditVulnerability(data, index)}
                        >
                          Edit
                        </Button>
                      )}
                      {hasPermission('delete:vulnerablecustomer') && (
                        <Button size="small" onClick={() => onDeleteVulnerability(data)}>
                          Delete
                        </Button>
                      )}
                    </Stack>
                  </StyledVulnerabilityHeader>
                  <List sx={{ width: '100%' }}>
                    <ListRow label="Temporary">{booleanAnswer(isTemporary)}</ListRow>
                    <ListRow label="Consent obtained">
                      {booleanAnswer(consentObtained)}
                    </ListRow>
                    <ListRow label="Category">{category}</ListRow>
                    <ListRow label="Type">{getVulnerabilityTypeName(type!)}</ListRow>
                    <ListRow label="Action">
                      {getVulnerabilityActionName(actionRequired!)}
                    </ListRow>
                    <ListRow label="Created on">{formatDateTime(createdAtUtc!)}</ListRow>
                    <ListRow label="Created by">{createdBy}</ListRow>
                    <ListRow label="Description">{description}</ListRow>
                    <ListRow label="Follow-up required">
                      {booleanAnswer(followUpRequired)}
                    </ListRow>
                    {followUpDate ? (
                      <ListRow label="Follow-up date/time">
                        {formatDateTime(followUpDate)}
                      </ListRow>
                    ) : null}
                    <ListRow label="Resolved">{booleanAnswer(resolved)}</ListRow>
                    {resolved && resolvedAtUtc ? (
                      <>
                        <ListRow label="Resolved on">
                          {formatDateTime(resolvedAtUtc)}
                        </ListRow>
                        <ListRow label="Resolved by">{resolvedBy}</ListRow>
                      </>
                    ) : null}
                    {!!comments?.length && (
                      <ListRow label="Comments">
                        {comments.map(comment => (
                          <Stack key={comment.createdAtUtc} direction="column">
                            <Box>{comment.description}</Box>
                            <Box sx={{ fontStyle: 'italic' }}>
                              {`Added on ${formatDateTime(comment.createdAtUtc!)} by ${
                                comment.createdBy
                              }`}
                            </Box>
                          </Stack>
                        ))}
                      </ListRow>
                    )}
                  </List>
                </VulnerabilitiesListItem>
              );
            })}
          </List>
        ) : (
          <Alert severity="info">This driver does not have any vulnerabilities</Alert>
        )}
      </Box>
      {hasPermission('create:vulnerablecustomer') && (
        <StyledButtonsFooter sticky>
          <Box>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={onAddNewVulnerability}
            >
              Add new vulnerability
            </Button>
          </Box>
        </StyledButtonsFooter>
      )}
    </>
  );
}
