import { z } from 'zod';
import { addMinutes, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { getMtaMaxStateDate, getMtaMinStartDate } from '../../helpers/newMtaFormHelper';
import { NewMtaCarChange } from '../../newMtaTypes';
import { newMtaCarFormSchema } from './newMtaCar.schema';
import { newMtaDriversFormSchema } from './newMtaDrivers.schema';
import { newMtaCoverFormSchema } from './newMtaCover.schema';
import { MotorPolicy } from '@/api/motorpolicy';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { getDate } from '@/helpers/utils/dateHelpers';

export const newMtaFormSchema = z
  .object({
    _policy: z.custom<MotorPolicy>(),
    _isQuoteLoaded: z.boolean(),
    startDate: z
      .date()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    mainDriverIndex: z.number(),
    drivers: newMtaDriversFormSchema,
    car: newMtaCarFormSchema,
    cover: newMtaCoverFormSchema,
  })
  .superRefine(({ startDate, _policy }, ctx) => {
    const policyStartDate = new Date(_policy?.cover?.startDateTimeUtc);
    const policyEndDate = new Date(_policy?.cover?.endDateTimeUtc);
    const todayDate = addMinutes(getDate(), 1);
    const isPolicyStartsLater = policyStartDate > todayDate;
    const isPolicyEndingEarlier = policyEndDate < todayDate;

    if (startDate && isBefore(startDate, getMtaMinStartDate(_policy))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['startDate'],
        message: isPolicyStartsLater
          ? 'Min date is policy start date'
          : 'Min date is today',
      });
    }

    if (startDate && isAfter(startDate, getMtaMaxStateDate(_policy))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['startDate'],
        message: isPolicyEndingEarlier
          ? 'Max date is policy end date'
          : 'Max date is 30 days from today',
      });
    }
  })
  .refine(
    ({ startDate, drivers, _policy }) => {
      if (!startDate) return true;

      const minStartDate = getMtaMinStartDate(_policy);
      const isNearestAvailableDate = isEqual(
        startOfDay(minStartDate),
        startOfDay(startDate),
      );
      if (isNearestAvailableDate) return true;

      const hasNewClaims = drivers.some(driver => {
        const isExistingDriver = !!driver._driverId;
        const hasAddedClaims = driver.claims.some(claim => !claim._isExisting);

        return isExistingDriver && hasAddedClaims;
      });

      return !hasNewClaims;
    },
    {
      path: ['startDate'],
      message:
        'The date must be set to the nearest available date when adding a new claim',
    },
  )
  .refine(
    ({
      car: { whatNeedsChanging, hasVerifiedCarNotImpounded, _queryRegistration },
      _policy,
    }) =>
      whatNeedsChanging !== NewMtaCarChange.NewCar ||
      _queryRegistration === _policy.vehicle.registrationNumber ||
      hasVerifiedCarNotImpounded,
    {
      path: ['car.hasVerifiedCarNotImpounded'],
      message:
        'Please verify that this vehicle has not been impounded, as we will not cover impounded cars.',
    },
  );

export type NewMtaFormSchema = z.input<typeof newMtaFormSchema>;
