import { z } from 'zod';
import { addMinutes, isBefore } from 'date-fns';
import { CommunicationMethod, NoteInitiator, NoteType } from '@/api/note';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { AttachmentReceivingMethod, AttachmentType } from '@/api/note';
import { NonNullableFields } from '@/helpers/utils/typesHelpers';
import { getDate, resetSecondsAndMilliseconds } from '@/helpers/utils/dateHelpers';

const MIN_SUMMARY_LENGTH = 5;
const MAX_SUMMARY_LENGTH = 250;
const MIN_DESCRIPTION_LENGTH = 5;
const MAX_DESCRIPTION_LENGTH = 500;
const MAX_NUM_OF_NEW_ATTACHMENETS = 3;

export const getDateOfReminderMinDate = () =>
  resetSecondsAndMilliseconds(addMinutes(getDate(), 1));

export const noteFormSchema = z
  .object({
    noteType: z
      .nativeEnum(NoteType)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    noteInitiator: z
      .nativeEnum(NoteInitiator)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    communicationMethod: z
      .nativeEnum(CommunicationMethod)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    summary: z
      .string()
      .min(MIN_SUMMARY_LENGTH, {
        message: validationMessages.minLength(MIN_SUMMARY_LENGTH),
      })
      .max(MAX_SUMMARY_LENGTH, {
        message: validationMessages.maxLength(MAX_SUMMARY_LENGTH),
      }),
    description: z
      .string()
      .min(MIN_DESCRIPTION_LENGTH, {
        message: validationMessages.minLength(MIN_DESCRIPTION_LENGTH),
      })
      .max(MAX_DESCRIPTION_LENGTH, {
        message: validationMessages.maxLength(MAX_DESCRIPTION_LENGTH),
      }),
    dateOfReminder: z
      .date()
      .nullable()
      .refine(
        value => !value || !isBefore(value, getDateOfReminderMinDate()),
        () => ({
          message: validationMessages.minDateTime(getDateOfReminderMinDate()),
        }),
      ),
    assigneeTeam: z.string(),
    existingAttachments: z.array(
      z.object({
        id: z.string(),
        fileName: z.string(),
      }),
    ),
    newAttachments: z
      .array(
        z.object({
          file: z.instanceof(File),
          type: z.nativeEnum(AttachmentType),
          receivingMethod: z.nativeEnum(AttachmentReceivingMethod),
        }),
      )
      .refine(
        newAttachments => newAttachments.length <= MAX_NUM_OF_NEW_ATTACHMENETS,
        `You can add up to ${MAX_NUM_OF_NEW_ATTACHMENETS} attachments in one request`,
      ),
  })
  .refine(
    ({ assigneeTeam, dateOfReminder }) => !dateOfReminder || Boolean(assigneeTeam),
    {
      path: ['assigneeTeam'],
      message: validationMessages.required,
    },
  );

export type NoteFormSchema = z.input<typeof noteFormSchema>;
export type NoteFormSubmitData = NonNullableFields<NoteFormSchema, 'dateOfReminder'>;
