import { PropsWithChildren } from 'react';
import { QuoteCalculationAlert } from './components/QuoteCalculationAlert';
import { QuoteCalculationCostSummary } from './components/QuoteCalculationCostSummary';
import { QuoteCalculationPriceBreakdown } from './components/QuoteCalculationPriceBreakdown';
import { QuoteCalculationPaymentPlans } from './components/QuoteCalculationPaymentPlans';
import { QuoteCalculationPrimaryActions } from './components/QuoteCalculationPrimaryActions';
import { QuoteCalculationUpdateFeesPremiumAction } from './components/QuoteCalculationUpdateFeesPremiumAction';
import { QuoteCalculationLoading } from './components/QuoteCalculationLoading';
import { QuoteCalculationError } from './components/QuoteCalculationError';
import { QuoteCalculationPaymentPlansFormProvider } from './components/QuoteCalculationPaymentPlansFormProvider';
import { QuoteCalculationContainer } from './components/QuoteCalculationContainer';
import { QuoteCalculationCreateOrderAction } from './components/QuoteCalculationCreateOrderAction';
import { QuoteCalculationProvider } from './components/QuoteCalculationProvider';
import { QuoteCalculationClearCalculationAction } from './components/QuoteCalculationClearCalculationAction';
import { QuoteCalculationSecondaryActions } from './components/QuoteCalculationSecondaryActions';
import { PaymentPlan, MotorQuote } from '@/api/motorquote';
import { FeeType } from '@/api/motorquote';

type QuoteCalculationProps = PropsWithChildren<{
  quote: MotorQuote;
  feeType: FeeType;
  customizeQuote?: {
    paymentPlans?: PaymentPlan[];
  };
  total: number;
}>;

export function QuoteCalculation({ children, ...providerProps }: QuoteCalculationProps) {
  return (
    <QuoteCalculationProvider {...providerProps}>
      <QuoteCalculationPaymentPlansFormProvider>
        <QuoteCalculationContainer>{children}</QuoteCalculationContainer>
      </QuoteCalculationPaymentPlansFormProvider>
    </QuoteCalculationProvider>
  );
}

QuoteCalculation.Loading = QuoteCalculationLoading;
QuoteCalculation.Error = QuoteCalculationError;
QuoteCalculation.Alert = QuoteCalculationAlert;
QuoteCalculation.CostSummary = QuoteCalculationCostSummary;
QuoteCalculation.PriceBreakdown = QuoteCalculationPriceBreakdown;
QuoteCalculation.PaymentPlans = QuoteCalculationPaymentPlans;
QuoteCalculation.PrimaryActions = QuoteCalculationPrimaryActions;
QuoteCalculation.SecondaryActions = QuoteCalculationSecondaryActions;
QuoteCalculation.CreateOrderAction = QuoteCalculationCreateOrderAction;
QuoteCalculation.UpdateFeesPremiumAction = QuoteCalculationUpdateFeesPremiumAction;
QuoteCalculation.ClearCalculationAction = QuoteCalculationClearCalculationAction;
