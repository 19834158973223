import { PropsWithChildren, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router';
import { Loading } from '@/components/ui/loaders/Loading';

export function AuthRoute({ children }: PropsWithChildren) {
  const location = useLocation();
  const { isAuthenticated, signinRedirect, activeNavigator } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && !activeNavigator) {
      void signinRedirect({
        state: {
          returnPath: location.pathname,
        },
      });
    }
  }, [isAuthenticated, activeNavigator]);

  if (!isAuthenticated) {
    return <Loading />;
  }

  return <>{children}</>;
}
