import { Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { NewMtaCarDetailsChangeNothing } from './components/NewMtaCarDetailsChangeNothing';
import { NewMtaCarDetailsChangeCar } from './components/NewMtaCarDetailsChangeCar';
import { NewMtaCarDetailsChangeReg } from './components/NewMtaCarDetailsChangeReg';
import { mapPolicyToNewMtaCarForm } from '@/features/NewMta/helpers/mapPolicyToNewMtaForm/mapPolicyToNewMtaCarForm';
import { NewMtaCarChange } from '@/features/NewMta/newMtaTypes';
import { getNewMtaCarChangeName } from '@/features/NewMta/helpers/newMtaNamesHelper';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';

export function NewMtaCarDetails() {
  const { setValue, watch, getValues } = useFormContext<NewMtaFormSchema>();
  const carChange = watch('car.whatNeedsChanging');

  const renderChangeOption = () => {
    switch (carChange) {
      case NewMtaCarChange.ExistingCarOrNothing:
        return <NewMtaCarDetailsChangeNothing />;
      case NewMtaCarChange.NewCar:
        return <NewMtaCarDetailsChangeCar />;
      case NewMtaCarChange.Registration:
        return <NewMtaCarDetailsChangeReg />;
      default:
        return null;
    }
  };

  const handleWhatNeedsChangingChange = (event: ChangeEvent<HTMLInputElement>) => {
    const whatNeedsChanging = event.target.value as NewMtaCarChange;
    const policy = getValues('_policy');
    const defaultForm = mapPolicyToNewMtaCarForm(policy.vehicle, policy.policyholder);
    setValue('car', { ...defaultForm, whatNeedsChanging });
  };

  return (
    <section aria-label="Car details">
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Car details
      </Typography>
      <FormTextField
        select
        name="car.whatNeedsChanging"
        label="What needs changing?"
        sx={{ ...commonSx, ...maxWidthSx }}
        onChange={handleWhatNeedsChangingChange}
        testId="new-mta_car-change-type-selectbox"
      >
        {renderSelectOptionsByEnum(NewMtaCarChange, getNewMtaCarChangeName)}
      </FormTextField>
      {renderChangeOption()}
    </section>
  );
}
