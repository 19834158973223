import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export function QuoteCalculationContainer({ children }: PropsWithChildren) {
  return (
    <Stack height="100%" direction="column" p={6} gap={6} flex={1}>
      {children}
    </Stack>
  );
}
