import { Outlet } from 'react-router';
import { EverydayinsureNavbar } from './components/ui/presentation/EverydayinsureNavbar';

export function PublicRoot() {
  return (
    <>
      <EverydayinsureNavbar />
      <main>
        <Outlet />
      </main>
    </>
  );
}
