import { useState } from 'react';
import { Alert, AlertProps, Snackbar, styled } from '@mui/material';

type SnackbarAlertProps = {
  message: string;
  onClose?: () => void;
} & Pick<AlertProps, 'severity'>;

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
}));

export function SnackbarAlert({ severity, message, onClose }: SnackbarAlertProps) {
  const [wasClosed, setClosed] = useState(false);

  const handleCloseAlert = () => {
    setClosed(true);
    onClose?.();
  };

  return (
    <StyledSnackbar autoHideDuration={3000} open={!wasClosed} onClose={handleCloseAlert}>
      <Alert
        aria-label={message}
        severity={severity}
        onClose={handleCloseAlert}
        variant="snackbar"
      >
        {message}
      </Alert>
    </StyledSnackbar>
  );
}
