import { Box, List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { getExcessName } from '@/helpers/nameMappings/excessNameMappings';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { MotorPolicy } from '@/api/motorpolicy';
import { getPolicyEndDate } from '@/helpers/data/policyHelpers';

type CoverDetailsProps = {
  policy: MotorPolicy;
};

export function CoverDetails({ policy }: CoverDetailsProps) {
  const { cover, excesses } = policy;

  return (
    <Box my={2}>
      <List disablePadding>
        <ListRow label="Start date">{formatDateTime(cover.startDateTimeUtc)}</ListRow>
        <ListRow label="End date">{formatDateTime(getPolicyEndDate(policy))}</ListRow>
        <ListRow label="Cover type">{cover.type.description}</ListRow>
        <ListRow label="Underwriter">{cover.underwriter}</ListRow>
      </List>
      {excesses?.compulsoryAmounts && (
        <div role="region" aria-labelledby="compulsory-region-heading">
          <Typography
            variant="subtitle2"
            id="compulsory-region-heading"
            sx={{ mt: 2, mb: 2 }}
          >
            Compulsory Excesses
          </Typography>
          <List disablePadding>
            {excesses.compulsoryAmounts.map((excess, index) => (
              <ListRow
                key={index}
                label={getExcessName(excess.type?.code, excess.description)}
              >
                {excess.total && formatCurrency(excess.total)}
              </ListRow>
            ))}
          </List>
        </div>
      )}
      {excesses?.voluntaryAmounts && (
        <div role="region" aria-labelledby="voluntary-region-heading">
          <Typography
            variant="subtitle2"
            id="voluntary-region-heading"
            sx={{ mt: 2, mb: 2 }}
          >
            Voluntary Excesses
          </Typography>
          <List disablePadding>
            {excesses.voluntaryAmounts.map((excess, index) => (
              <ListRow
                key={index}
                label={getExcessName(excess.type?.code, excess.description)}
              >
                {excess.total && formatCurrency(excess.total)}
              </ListRow>
            ))}
          </List>
        </div>
      )}
      <List disablePadding>
        <ListRow label="Preferred payment type">
          {policy.usualPaymentFrequency?.description}
        </ListRow>
      </List>
      <List disablePadding>
        <ListRow label="Was Protected NCB at quote creation">
          {booleanAnswer(policy.wasProtectedNcbAtQuoteCreation)}
        </ListRow>
      </List>
    </Box>
  );
}
