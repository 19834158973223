import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { DebtReason } from '@/api/support';

const bypassPaymentFormSchema = z.object({
  reason: z
    .nativeEnum(DebtReason)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
});

export type BypassPaymentFormSchema = z.input<typeof bypassPaymentFormSchema>;

export function useBypassPaymentForm() {
  return useForm<BypassPaymentFormSchema>({
    defaultValues: {
      reason: null,
    },
    resolver: zodResolver(bypassPaymentFormSchema),
  });
}
