import { useEffect } from 'react';
import { FileDetailProps, useFileDetail } from './hooks/useFileDetail';
import { FileDetailImageModal } from './components/FileDetailImageModal';
import { FileDetailVideoModal } from './components/FileDetailVideoModal';
import { FileDetailPresentation } from './components/FileDetailPresentation';
import { ActionType, FileCategory } from './fileDetailTypes';

export function FileDetail(props: FileDetailProps) {
  const { fileName, title, description1, description2, content, isError, url, testId } =
    props;
  const {
    pendingAction,
    category,
    isModalOpen,
    runPendingAction,
    handleAction,
    handleCloseModal,
  } = useFileDetail(props);

  const renderModal = () => {
    if (!content || !category || !isModalOpen) {
      return null;
    }

    const props = {
      fileName,
      content,
      title,
      onClose: handleCloseModal,
    };

    switch (category) {
      case FileCategory.Image:
        return <FileDetailImageModal key={content} {...props} />;
      case FileCategory.Video:
        return <FileDetailVideoModal key={content} {...props} />;
    }
  };

  useEffect(() => {
    if (pendingAction && content) {
      runPendingAction(pendingAction, content);
    }
  }, [pendingAction, content]);

  return (
    <>
      <FileDetailPresentation
        title={title}
        description1={description1}
        description2={description2}
        isViewFileDisabled={!category}
        onViewFile={() => handleAction(ActionType.View)}
        onDownloadFile={() => handleAction(ActionType.Download)}
        isError={isError}
        pendingAction={pendingAction}
        url={url}
        category={category}
        testId={testId}
      />
      {renderModal()}
    </>
  );
}
