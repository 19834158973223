import { MotorPolicyService } from './services/MotorPolicyService';
import { MtaService } from './services/MtaService';
import { BaseAPI } from '../core/BaseAPI';
import { env } from '@/config/env';

export class MotorpolicyAPI extends BaseAPI {
  readonly motorPolicy: MotorPolicyService;
  readonly mta: MtaService;

  constructor() {
    super({ BASE: env.config.MOTORPOLICY_API_URL });

    this.motorPolicy = new MotorPolicyService(this.request);
    this.mta = new MtaService(this.request);
  }
}
