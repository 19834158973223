import { NavLink } from 'react-router';
import { PropsWithChildren } from 'react';
import { SideNavIcon } from './SideNavIcon';

type SideNavLinkProps = PropsWithChildren<{
  title: string;
  to: string;
}>;

export function SideNavLink({ title, to, children }: SideNavLinkProps) {
  return (
    <NavLink title={title} to={to}>
      {({ isActive }) => <SideNavIcon isActive={isActive}>{children}</SideNavIcon>}
    </NavLink>
  );
}
