import { z } from 'zod';
import { VehicleFuelType, VehicleTransmissionType } from '@/api/retail/v1';
import { carRegistrationRegex } from '@/helpers/constants/regex';
import { validationMessages } from '@/helpers/utils/textHelpers';

export const newMtaCarDetailsFormSchema = z.object({
  registration: z
    .string()
    .regex(carRegistrationRegex, 'Enter a valid registration')
    .refine(value => Boolean(value), validationMessages.required),
  registrationYear: z
    .number()
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  make: z
    .string()
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  fuelType: z
    .nativeEnum(VehicleFuelType)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  transmission: z
    .nativeEnum(VehicleTransmissionType)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  model: z
    .string()
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
});

export type NewMtaCarDetailsFormSchema = z.input<typeof newMtaCarDetailsFormSchema>;
