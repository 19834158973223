import { useController, useFormContext } from 'react-hook-form';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  type TextFieldProps,
} from '@mui/material';
import { ChangeEvent, PropsWithChildren } from 'react';
import { StyledFormTextFieldBasic } from './FormTextField.styles';
import { FieldError } from './components/FieldError';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/utils/formHelpers';
import { pipe } from '@/helpers/utils/typesHelpers';

type DisplayStyle = 'default' | 'basic';

type Transformer = (value: string) => string;

type FormTextFieldProps = {
  name: string;
  testId?: string;
  loading?: boolean;
  displayStyle?: DisplayStyle;
  transformers?: Transformer[];
} & TextFieldProps &
  PropsWithChildren;

function getLoadingProps(): TextFieldProps {
  return {
    disabled: true,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <CircularProgress size={20} />
        </InputAdornment>
      ),
    },
    SelectProps: {
      IconComponent: () => null,
    },
  };
}

export function FormTextField({
  name,
  loading = false,
  testId,
  children,
  displayStyle = 'default',
  transformers,
  ...props
}: FormTextFieldProps) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...fieldProps },
    fieldState: { error },
  } = useController({ control, name });

  const loadingProps = loading ? getLoadingProps() : undefined;
  const fieldValue = (value as number | string | null | undefined) ?? '';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const transformedValue = transformers
      ? // @ts-expect-error A spread argument must either have a tuple type or be passed to a rest parameter.ts(2556)
        pipe(newValue, ...transformers)
      : newValue;

    onChange(transformedValue);
  };

  if (displayStyle === 'basic') {
    return (
      <Stack direction="column" gap={2} sx={props.sx}>
        <Typography component="label" variant="body2">
          {props.label}
        </Typography>
        <Stack direction="column" gap={1}>
          <StyledFormTextFieldBasic
            $isError={!!error}
            name={fieldProps.name}
            value={fieldValue}
            onChange={handleChange}
          />
          {error?.message ? <FieldError>{error.message}</FieldError> : null}
        </Stack>
      </Stack>
    );
  }

  return (
    <TextField
      onChange={handleChange}
      value={fieldValue}
      {...getErrorProps(error)}
      {...fieldProps}
      {...props}
      {...loadingProps}
      {...overrideReactHookFormRef}
      slotProps={{
        ...props.slotProps,
        input: {
          ...props.slotProps?.input,
          // @ts-expect-error data-testid not included in typings but works correctly
          'data-testid': testId,
        },
        formHelperText: {
          // @ts-expect-error data-testid not included in typings but works correctly
          'data-testid': testId && `${testId}-helper-text`,
        },
      }}
    >
      {children}
    </TextField>
  );
}
