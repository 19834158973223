import { Button, Stack, Typography } from '@mui/material';
import { NewMtaCarModItem } from './components/NewMtaCarModItem';
import { useNewMtaCarMods } from './hooks/useNewMtaCarMods';
import { useVehicleModifications } from '@/features/NewMta/hooks';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormComboBox } from '@/components/ui/forms/FormComboBox';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';

export function NewMtaCarMods() {
  const { data: vehicleModsOptions = [] } = useVehicleModifications();
  const {
    modifications,
    isAddingNewModification,
    shouldShowModificationsList,
    addNewModification,
    cancelNewModification,
    removeModification,
  } = useNewMtaCarMods();

  return (
    <section aria-label="Car modifications">
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Car modifications
      </Typography>
      <FormRadioGroup
        name="car.modifiedVehicle"
        label="Modified car"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          label="Yes"
          value={YesNo.Yes}
          testId="new-mta_car-modified-yes-radio"
        />
        <FormRadio label="No" value={YesNo.No} testId="new-mta_car-modified-no-radio" />
      </FormRadioGroup>
      {shouldShowModificationsList && (
        <>
          {modifications.map((mod, index) =>
            mod ? (
              <NewMtaCarModItem
                key={index}
                sx={{ ...commonSx, ...maxWidthSx }}
                mod={mod}
                modNo={index + 1}
                onRemove={() => removeModification(index)}
              />
            ) : (
              <FormComboBox
                key={index}
                label="Modification"
                name={`car.modifications.${index}`}
                sx={{ ...commonSx, ...maxWidthSx }}
                options={vehicleModsOptions}
                testId="new-mta_car-modification-selectbox"
              />
            ),
          )}
          <Stack gap={3} sx={commonSx}>
            {isAddingNewModification ? (
              <Button size="small" onClick={cancelNewModification}>
                Cancel
              </Button>
            ) : (
              <Button variant="contained" size="small" onClick={addNewModification}>
                Add another modification
              </Button>
            )}
          </Stack>
        </>
      )}
    </section>
  );
}
