import { useContext } from 'react';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useReversalQuoteMutation } from '@/hooks/mutations/useReversalQuoteMutation';
import { useMotorQuoteQuery } from '@/hooks/queries/useMotorQuoteQuery';

export function useHistoryReversalQuote() {
  const policyId = useContext(PolicyIdContext);
  const {
    mutate: createReversalQuote,
    data: { quoteId } = {},
    isError: isReversalQuoteError,
    isPending: isReversalQuotePending,
    error: reversalQuoteError,
    reset: resetReversalQuote,
  } = useReversalQuoteMutation();
  const {
    data: reversalQuote,
    isFetching: isMotorQuoteQueryFetching,
    isError: isMotorQuoteQueryError,
    error: motorQuoteError,
  } = useMotorQuoteQuery(quoteId ?? '', {
    enabled: !!quoteId,
  });

  const isQuoteFetching = isReversalQuotePending || isMotorQuoteQueryFetching;
  const isQuoteError = isReversalQuoteError || isMotorQuoteQueryError;
  const quoteError = reversalQuoteError ?? motorQuoteError;
  const shouldShowQuote = !isQuoteError && !!reversalQuote;

  const calculateReversalQuote = (transactionId: string) => {
    createReversalQuote({ policyId, transactionId });
  };

  return {
    isQuoteFetching,
    isQuoteError,
    shouldShowQuote,
    quoteError,
    reversalQuote,
    calculateReversalQuote,
    resetReversalQuote,
  };
}
