import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDocumentBlobQuery } from './queries/useDocumentBlobQuery';
import { useDocumentQuery } from './queries/useDocumentQuery';
import { useSnackbarAlert } from './useSnackbarAlert';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseDocumentProps = {
  url: string;
  enabled?: boolean;
};

export function useDocument({ url, enabled }: UseDocumentProps) {
  const queryClient = useQueryClient();
  const { setAlert } = useSnackbarAlert();
  const {
    data: response,
    isError: isDocumentQueryError,
    isFetching: isDocumentQueryFetching,
  } = useDocumentQuery(url, {
    enabled,
  });
  const {
    data,
    isError: isDocumentBlobQueryError,
    isFetching: isDocumentBlobQueryFetching,
  } = useDocumentBlobQuery(response, {
    enabled: !!response?.ok,
  });
  const isError = isDocumentQueryError || isDocumentBlobQueryError;
  const isFetching = isDocumentQueryFetching || isDocumentBlobQueryFetching;

  useEffect(() => {
    if (isError) {
      setAlert({ message: GENERAL_ERROR_MSG, severity: 'error' });

      return () => {
        queryClient.removeQueries({
          queryKey: queryKeys.document(url),
        });
      };
    }
  }, [isError]);

  return {
    data,
    isFetching,
    isError,
  };
}
