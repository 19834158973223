import { Alert, Button, Typography } from '@mui/material';
import { Modal } from '@/components/ui/modals/Modal';
import { useCreateOrderPromptContext } from '@/features/PaymentActions/QuoteCalculation';

export function CancelPolicyRefundAmountDelayModal() {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();
  return (
    <Modal onClose={cancelPrompt}>
      <Modal.Header>Refund amount delay</Modal.Header>
      <Modal.Content>
        <Alert severity="warning">
          The refund amount can take up to 24 hours to update
        </Alert>
        <Typography variant="body2">
          Please confirm to the customer that the refund amount quoted may change as we
          are waiting for Close Brothers to update your loan redemption amount in our
          system, which can take up to 24 hours.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" onClick={confirmPrompt}>
          Confirm and continue
        </Button>
        <Button variant="outlined" color="inherit" onClick={cancelPrompt}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
