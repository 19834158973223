import { DebtType, DebtReason as MotorPolicyDebtReason } from '@/api/motorpolicy';
import {
  CreditAgreementStatus,
  DebtReason as SupportDebtReason,
  PaymentProvider,
} from '@/api/support';
import { BypassRefundReason } from '@/models/BypassRefundReason';

export function getDebtReasonName(
  debtReason: MotorPolicyDebtReason | SupportDebtReason | BypassRefundReason,
): string {
  switch (debtReason) {
    case MotorPolicyDebtReason.CANCELLATION_FEE_AMENDMENT:
    case SupportDebtReason.CANCELLATION_FEE_AMENDMENT:
      return 'Cancellation Fee Amendment';
    case MotorPolicyDebtReason.COMPENSATION:
    case SupportDebtReason.COMPENSATION:
      return 'Compensation';
    case MotorPolicyDebtReason.DEBT_SETTLEMENT:
    case SupportDebtReason.DEBT_SETTLEMENT:
      return 'Debt settlement';
    case MotorPolicyDebtReason.FORCED_CANCELLATION:
    case SupportDebtReason.FORCED_CANCELLATION:
      return 'Forced cancellation';
    case MotorPolicyDebtReason.FOSCOMPENSATION:
    case SupportDebtReason.FOSCOMPENSATION:
      return 'FOS Compensation';
    case MotorPolicyDebtReason.MTAFEE_AMENDMENT:
    case SupportDebtReason.MTAFEE_AMENDMENT:
      return 'MTA Fee Amendment';
    case MotorPolicyDebtReason.PAYMENT_ARREARS:
    case SupportDebtReason.PAYMENT_ARREARS:
      return 'Payment arrears';
    case MotorPolicyDebtReason.SET_UP_FEE_AMENDMENT:
    case SupportDebtReason.SET_UP_FEE_AMENDMENT:
      return 'Set-up Fee Amendment';
    case MotorPolicyDebtReason.UNCHARGED_PREMIUM:
    case SupportDebtReason.UNCHARGED_PREMIUM:
      return 'Uncharged premium';
    case MotorPolicyDebtReason.WRITE_OFF:
    case SupportDebtReason.WRITE_OFF:
      return 'Write-Off';
    case BypassRefundReason.NO_REFUND_BROKER_REQUEST:
      return 'No Refund - broker request';
    case BypassRefundReason.NO_REFUND_INSURER_REQUEST:
      return 'No Refund - insurer request';
  }
}

export function getDebtTypeName(debtType: DebtType): string {
  switch (debtType) {
    case DebtType.OWED_TO_THE_COMPANY:
      return 'Owed to us';
    case DebtType.OWED_TO_THE_CUSTOMER:
      return 'Owed to customer';
  }
}

export function getPaymentProviderName(paymentProvider: PaymentProvider): string {
  switch (paymentProvider) {
    case PaymentProvider.ADYEN:
      return 'Adyen';
    case PaymentProvider.CLOSE_BROTHERS:
      return 'Close Brothers';
  }
}

export function getCreditAgreementStatus(
  creditAgreementStatus: CreditAgreementStatus,
): string {
  switch (creditAgreementStatus) {
    case CreditAgreementStatus.ACTIVE:
      return 'Active';
    case CreditAgreementStatus.CANCELLED:
      return 'Cancelled';
    case CreditAgreementStatus.UNAUTHORISED:
      return 'Unauthorised';
  }
}
