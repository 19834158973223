import { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Divider } from '@mui/material';
import { ReminderDetailList } from '@/features/Reminders/ReminderDetailList';
import { MarkAsCompleteModal } from '@/features/Reminders/MarkAsCompleteModal';
import { useCompleteReminderMutation } from '@/hooks/mutations/useCompleteReminderMutation';
import { ReminderDetail } from '@/api/note';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';

type IndividualRemindersAccordionContentProps = {
  reminder: ReminderDetail;
  refetch: UseQueryResult['refetch'];
};

export function IndividualRemindersAccordionContent({
  reminder,
}: IndividualRemindersAccordionContentProps) {
  const { setAlert } = useSnackbarAlert({ closeOnUnmount: false });
  const [isMarkAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);
  const closeMarkAsCompleteModal = () => setMarkAsCompleteModalOpen(false);
  const openMarkAsCompleteModal = () => setMarkAsCompleteModalOpen(true);

  const handleMarkAsCompleteSuccess = () => {
    setAlert({ message: 'Reminder has been marked as complete', severity: 'success' });
    closeMarkAsCompleteModal();
  };

  const {
    mutate: completeReminderMutate,
    isPending: isCompleteReminderPending,
    isError: isCompleteReminderError,
  } = useCompleteReminderMutation({ onSuccess: handleMarkAsCompleteSuccess });

  const handleMarkAsComplete = () => {
    completeReminderMutate(reminder.reminderId);
  };

  return (
    <>
      <Divider />
      <ReminderDetailList
        reminder={reminder}
        openMarkAsCompleteModal={openMarkAsCompleteModal}
      />
      {isMarkAsCompleteModalOpen && (
        <MarkAsCompleteModal
          isError={isCompleteReminderError}
          isPending={isCompleteReminderPending}
          onConfirm={handleMarkAsComplete}
          onCancel={closeMarkAsCompleteModal}
        />
      )}
    </>
  );
}
