import { useEffect } from 'react';
import { useSelectedPaymentPlan } from '../../../hooks/useSelectedPaymentPlan';
import { useCreateOrderActionContext } from '../../../hooks/useCreateOrderContext';
import { useQuoteCalculationContext } from '../../../hooks/useQuoteCalculationContext';
import { useQuoteCalculationCreateOrderActionContext } from '../hooks/useQuoteCalculationCreateOrderActionContext';
import { useOrder } from '@/hooks/useOrder';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import { useSetAlertError } from '@/hooks/useSetAlertError';
import { getDebtReasonName } from '@/helpers/nameMappings/paymentNameMappings';

type QuoteCalculationMakeNonPaymentOrderProps = {
  includeSelectedPaymentPlanInNonPaymentRequest: boolean;
  onSuccess: () => void;
};

export function QuoteCalculationMakeNonPaymentOrder({
  includeSelectedPaymentPlanInNonPaymentRequest,
  onSuccess,
}: QuoteCalculationMakeNonPaymentOrderProps) {
  const selectedPaymentPlan = useSelectedPaymentPlan();
  const { quoteId, quoteResult } = useQuoteCalculationContext();
  const { bypassRefundReason, shouldBypassRefund } =
    useQuoteCalculationCreateOrderActionContext();

  const { setLoading } = useCreateOrderActionContext();
  const {
    makeOrder,
    isPending: isOrderPending,
    isError: isOrderError,
    isSuccess: isOrderSuccess,
  } = useOrder();

  useEffectOnce(() => {
    makeOrder({
      type: 'quote',
      quoteId,
      isRefund: quoteResult === 'refund',
      ...(includeSelectedPaymentPlanInNonPaymentRequest
        ? { selectedPaymentPlan: selectedPaymentPlan!.type!.code! }
        : {}),
      ...(quoteResult === 'refund' && shouldBypassRefund && bypassRefundReason
        ? {
            bypassPayment: true,
            bypassPaymentReasonCode: bypassRefundReason,
            bypassPaymentDescription: getDebtReasonName(bypassRefundReason),
          }
        : {}),
    });
  });

  useEffect(() => {
    setLoading(isOrderPending);
  }, [isOrderPending]);

  useSetAlertError(isOrderError);

  useEffect(() => {
    if (isOrderSuccess) {
      onSuccess();
    }
  }, [isOrderSuccess]);

  return null;
}
