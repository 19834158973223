import { Alert, Button, Divider, List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { Endorsement } from '@/api/retail/v2';
import { Modal } from '@/components/ui/modals/Modal';
import { useCreateOrderPromptContext } from '@/features/PaymentActions/QuoteCalculation';

type EndorsementsModalProps = {
  endorsements: Endorsement[];
};

export function NewMtaEndorsementsModal({ endorsements }: EndorsementsModalProps) {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();

  return (
    <Modal onClose={cancelPrompt}>
      <Modal.Header>Conditions (endorsements)</Modal.Header>
      <Modal.Content>
        <Alert severity="warning">
          There are endorsements that will be applied to this policy
        </Alert>
        <Typography variant="body2">
          Please confirm with the customer that there are endorsements applied to their
          policy. They will need to agree to each endorsement before you can continue.
          Provide the summary of each endorsement below and explain full terms and
          conditions of each endorsement is on page 24 of their Policy Wording.
        </Typography>
        <Divider />
        <List>
          {endorsements.map(({ code, type, description }) => (
            <ListRow key={code} label={type ?? ''}>
              {description}
            </ListRow>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" onClick={confirmPrompt}>
          Confirm and continue
        </Button>
        <Button variant="outlined" color="inherit" onClick={cancelPrompt}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
