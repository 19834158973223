import { Divider, List, Typography } from '@mui/material';
import { InsurerClaimParty } from '@/api/motorpolicy';
import { ListRow } from '@/components/ui/lists/ListRow';
import {
  getClaimAddressTypeName,
  getClaimPartyTypeName,
} from '@/helpers/nameMappings/claimNameMappings';
import { formatDate, parseDateOnly } from '@/helpers/utils/dateHelpers';
import { formatUKPostcode } from '@/helpers/utils/textHelpers';

type ClaimContentPartyProps = {
  claimParty: InsurerClaimParty;
  claimNumber: number | undefined;
  claimPartyNo: number;
};

export function ClaimContentParty({
  claimParty,
  claimNumber,
  claimPartyNo,
}: ClaimContentPartyProps) {
  return (
    <>
      <Divider />
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Claim party #{claimPartyNo}
      </Typography>
      <List>
        <ListRow label="Claim number">{claimNumber}</ListRow>
        <ListRow label="Party type">
          {claimParty.partyType && getClaimPartyTypeName(claimParty.partyType)}
        </ListRow>
        <ListRow label="First name">{claimParty.firstName}</ListRow>
        <ListRow label="Last name">{claimParty.lastName}</ListRow>
        <ListRow label="D.O.B">
          {claimParty.dateOfBirth && formatDate(parseDateOnly(claimParty.dateOfBirth))}
        </ListRow>
        <ListRow label="Mobile number">{claimParty.mobileTelephoneNumber}</ListRow>
        <ListRow label="Landline number">{claimParty.landlineTelephoneNumber}</ListRow>
        <ListRow label="Email address">{claimParty.emailAddress}</ListRow>
        <ListRow label="Address type">
          {claimParty.addressType && getClaimAddressTypeName(claimParty.addressType)}
        </ListRow>
        <ListRow label="Vehicle registration">{claimParty.vehicleRegistration}</ListRow>
        <ListRow label="Address">
          {claimParty?.line1 && <div>{claimParty.line1}</div>}
          {claimParty?.line2 && <div>{claimParty.line2}</div>}
          {claimParty?.line3 && <div>{claimParty.line3}</div>}
          {claimParty?.line4 && <div>{claimParty.line4}</div>}
        </ListRow>
        <ListRow label="Postcode">
          {claimParty.postCode && formatUKPostcode(claimParty.postCode)}
        </ListRow>
      </List>
    </>
  );
}
