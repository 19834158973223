import {
  COMPREHENSIVE_COVER_TYPE_CODE,
  THIRD_PARTY_AND_THEFT_COVER_TYPE_CODE,
  THIRD_PARTY_ONLY_COVER_TYPE_CODE,
} from '@/features/NewMta/hooks/schemas/newMtaCover.schema';

export const COVER_OPTIONS_ORDERED_BY_COVERAGE = [
  THIRD_PARTY_ONLY_COVER_TYPE_CODE,
  THIRD_PARTY_AND_THEFT_COVER_TYPE_CODE,
  COMPREHENSIVE_COVER_TYPE_CODE,
];

export function getExcludedCoverTypeOptionCodes(initialCoverType: string) {
  const initialCoverTypeIndex =
    COVER_OPTIONS_ORDERED_BY_COVERAGE.indexOf(initialCoverType);

  if (initialCoverTypeIndex === -1) {
    return [];
  }

  return COVER_OPTIONS_ORDERED_BY_COVERAGE.slice(initialCoverTypeIndex + 1);
}
