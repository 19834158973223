import { MotorQuoteService } from './services/MotorQuoteService';
import { BaseAPI } from '../core/BaseAPI';
import { env } from '@/config/env';

export class MotorquoteAPI extends BaseAPI {
  readonly motorQuote: MotorQuoteService;

  constructor() {
    super({ BASE: env.config.MOTORQUOTE_API_URL });

    this.motorQuote = new MotorQuoteService(this.request);
  }
}
