import envsConfig from '../../envs.config.json';

type Env = {
  APP_URL: string;
  SUPPORT_API_URL: string;
  NOTE_API_URL: string;
  VULNERABILITIES_API_URL: string;
  MOTORPOLICY_API_URL: string;
  MOTORQUOTE_API_URL: string;
  RETAIL_API_URL: string;
  DOCUMENT_CREATION_API_URL: string;
  AUTH_AUTHORITY: string;
  AUTH_CLIENT_ID: string;
  AUTH_SCOPE: string;
  ADYEN_CLIENT_KEY: string;
  ADYEN_ENVIRONMENT: string;
  ADYEN_LOCALE: string;
};

let cachedEnvConfig: Env | null = null;

export const env = {
  get config() {
    if (cachedEnvConfig) {
      return cachedEnvConfig;
    }

    const envConfig = Object.values(envsConfig).find(
      ({ APP_URL }) => APP_URL === window.location.origin,
    );

    if (!envConfig) {
      throw new Error('Unable to find a configuration for this environment.');
    }

    cachedEnvConfig = envConfig;

    return envConfig;
  },
};
