import { useState } from 'react';
import { FileDetail } from '@/components/ui/presentation/FileDetail';
import { Document } from '@/models/Documents';
import { getFileNameFromUrl } from '@/helpers/utils/fileHelpers';
import { useDocument } from '@/hooks/useDocument';

type DocumentItemProps = {
  document: Document;
  testId?: string;
};

export function DocumentItem({ document, testId }: DocumentItemProps) {
  const [isContentRequest, setContentRequested] = useState(false);
  const { data, isError } = useDocument({
    url: document.url,
    enabled: isContentRequest,
  });

  const fileName = getFileNameFromUrl(document.url);

  return (
    <FileDetail
      title={document.description}
      fileName={fileName}
      content={data ?? null}
      onContentRequest={() => setContentRequested(true)}
      isError={isError}
      url={document.url}
      testId={testId}
    />
  );
}
