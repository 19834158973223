import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { validateOtherAmountToPay } from '../helpers/settleBalanceFormHelper';
import { YesNo } from '@/models/YesNo';
import { validationMessages } from '@/helpers/utils/textHelpers';

const settleBalanceFormSchema = z
  .object({
    _balance: z.number(),
    payInFull: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    otherAmountToPay: z.string(),
  })
  .superRefine((formFields, ctx) => {
    validateOtherAmountToPay(formFields, ctx);
  });

export type SettleBalanceFormSchema = z.input<typeof settleBalanceFormSchema>;

export function useSettleBalanceForm({ balance }: { balance: number }) {
  return useForm<SettleBalanceFormSchema>({
    defaultValues: {
      _balance: balance,
      payInFull: null,
      otherAmountToPay: '',
    },
    resolver: zodResolver(settleBalanceFormSchema),
  });
}
