import { useContext } from 'react';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useCancellationQuoteMutation } from '@/hooks/mutations/useCancellationQuoteMutation';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { useMotorQuoteQuery } from '@/hooks/queries/useMotorQuoteQuery';

export function useCancellationQuote() {
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const {
    data: cancellationQuoteMutationData,
    mutate,
    isPending: isCalculatePaymentPending,
    isError: isCalculatePaymentError,
    reset,
  } = useCancellationQuoteMutation({
    policyId: policy?.id ?? '',
  });
  const {
    data: cancellationQuoteQueryData,
    isFetching: isMotorQuoteQueryFetching,
    isError: isMotorQuoteQueryError,
  } = useMotorQuoteQuery(cancellationQuoteMutationData?.quoteId ?? '', {
    enabled: Boolean(cancellationQuoteMutationData?.quoteId),
  });

  return {
    isError: isCalculatePaymentError || isMotorQuoteQueryError,
    isPending: isCalculatePaymentPending || isMotorQuoteQueryFetching,
    cancelResponse: cancellationQuoteMutationData,
    quote: cancellationQuoteQueryData,
    mutate,
    reset,
  };
}
