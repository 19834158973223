import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ActionType, FileCategory } from '../../fileDetailTypes';
import { FileDetailDescription, FileDetailTitle } from './FileDetailPresentation.styles';

type FileDetailPresentationProps = {
  title: string;
  description1?: string;
  description2?: string;
  isViewFileDisabled: boolean;
  onViewFile: () => void;
  onDownloadFile: () => void;
  pendingAction?: ActionType | null;
  isError?: boolean;
  url?: string;
  category?: FileCategory | null;
  testId?: string;
};

export function FileDetailPresentation({
  title,
  description1,
  description2,
  isViewFileDisabled,
  onViewFile,
  onDownloadFile,
  isError,
  pendingAction,
  url,
  category,
  testId,
}: FileDetailPresentationProps) {
  const shouldRenderLink = category === FileCategory.Document && url;

  return (
    <Stack spacing={1}>
      <InsertDriveFileOutlinedIcon />
      {shouldRenderLink ? (
        <Link data-testid={testId} href={url} target="_blank" rel="noopener noreferrer">
          <FileDetailTitle mb={1}>{title}</FileDetailTitle>
          {description1 && <FileDetailDescription>{description1}</FileDetailDescription>}
          {description2 && <FileDetailDescription>{description2}</FileDetailDescription>}
        </Link>
      ) : (
        <Box>
          <FileDetailTitle mb={1}>{title}</FileDetailTitle>
          {description1 && <FileDetailDescription>{description1}</FileDetailDescription>}
          {description2 && <FileDetailDescription>{description2}</FileDetailDescription>}
          <Stack spacing={4} mt={4}>
            <LoadingButton
              variant="contained"
              size="small"
              disabled={isError || isViewFileDisabled}
              onClick={onViewFile}
              loading={!isError && pendingAction === ActionType.View}
            >
              View
            </LoadingButton>
            <LoadingButton
              size="small"
              loading={!isError && pendingAction === ActionType.Download}
              onClick={onDownloadFile}
              disabled={isError}
            >
              Download
            </LoadingButton>
          </Stack>
        </Box>
      )}
    </Stack>
  );
}
