import { formatDate, formatDateTime } from './dateHelpers';
import { YesNo } from '@/models/YesNo';

export const booleanAnswer = (value: boolean | null | undefined) =>
  value ? YesNo.Yes : YesNo.No;

export const validationMessages = {
  required: 'Required',
  maxLength: (len: number) => `Maximum ${len} characters allowed`,
  minLength: (len: number) => `Minimum ${len} characters required`,
  minValue: (val: number) => `Minimum value is ${val}`,
  maxValue: (val: number) => `Maximum value is ${val}`,
  email: 'This is not a valid email',
  minDate: (date: Date) => `Min date is ${formatDate(date)}`,
  maxDate: (date: Date) => `Max date is ${formatDate(date)}`,
  minDateTime: (date: Date) => `Min date is ${formatDateTime(date)}`,
  maxDateTime: (date: Date) => `Max date is ${formatDateTime(date)}`,
};

export function genderByTitle(title: string | null | undefined) {
  switch (title?.toLowerCase()) {
    case 'mr':
      return 'Male';
    case 'mrs':
    case 'miss':
    case 'ms':
      return 'Female';
    default:
      return 'Other';
  }
}

export function convertPascalCaseToText(value: string): string {
  return value
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
    .replace(/^(.)/, firstLetter => firstLetter.toUpperCase());
}

export function formatUKPostcode(postcode: string) {
  const cleanPostcode = postcode.replace(/\s+/g, '');
  const firstPart = cleanPostcode.slice(0, cleanPostcode.length - 3);
  const secondPart = cleanPostcode.slice(-3);

  return `${firstPart} ${secondPart}`;
}

export function toKebabCase(text: string): string {
  return text.toLowerCase().trim().replace(/\s+/g, '-');
}
