import { MenuItem, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { NewMtaFormSchema, useVehicleOptions } from '@/features/NewMta/hooks';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { YesNo } from '@/models/YesNo';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { FormRadio } from '@/components/ui/forms/FormRadio';

const numberOfSeats = Array.from(Array(10).keys()).map(key => key + 1);

export function NewMtaCarSpecs() {
  const { isError, isPending, data: vehicleOptions } = useVehicleOptions();
  const { watch } = useFormContext<NewMtaFormSchema>();
  const trackingDevice = watch('car.trackingDevice');

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return (
    <section aria-label="Car specs">
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Car specs
      </Typography>
      <FormRadioGroup
        name="car.steeringWheelSide"
        label="Steering wheel side"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          label="Left"
          value="L"
          testId="new-mta_car-steering-wheel-side-left-radio"
        />
        <FormRadio
          label="Right"
          value="R"
          testId="new-mta_car-steering-wheel-side-right-radio"
        />
      </FormRadioGroup>
      <FormTextField
        select
        name="car.numberOfSeats"
        label="Number of seats"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {numberOfSeats.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </FormTextField>
      <FormRadioGroup
        name="car.trackingDevice"
        label="Tracking device"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          label={YesNo.Yes}
          value={YesNo.Yes}
          testId="new-mta_car-tracking-device-yes-radio"
        />
        <FormRadio
          label={YesNo.No}
          value={YesNo.No}
          testId="new-mta_car-tracking-device-no-radio"
        />
      </FormRadioGroup>
      {trackingDevice === YesNo.Yes && (
        <FormTextField
          select
          name="car.trackingDeviceType"
          label="Tracking device type"
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          {renderSelectOptionsByOptionsCodes(vehicleOptions.trackingDevices ?? [])}
        </FormTextField>
      )}
      <FormTextField
        select
        name="car.alarmImmobiliser"
        label="Alarm / immobiliser"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(vehicleOptions.alarmImmobiliserTypes ?? [])}
      </FormTextField>
      <FormRadioGroup
        name="car.importedVehicle"
        label="Imported vehicle"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      <FormNumberField
        name="car.estimatedValue"
        label="Estimated value"
        sx={{ ...commonSx, ...maxWidthSx }}
        symbol="money"
      />
    </section>
  );
}
