import { Divider, List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer, formatUKPostcode } from '@/helpers/utils/textHelpers';
import { Policyholder } from '@/api/motorpolicy';

type PolicyholderHomeContentProps = {
  policyholder: Policyholder;
};

export function PolicyholderHomeContent({ policyholder }: PolicyholderHomeContentProps) {
  const formattedAddress = policyholder.address?.formattedAddress;

  return (
    <>
      <Divider sx={{ mt: -1 }} />
      <List>
        <ListRow label="Address">
          {formattedAddress?.line1 && <div>{formattedAddress.line1}</div>}
          {formattedAddress?.line2 && <div>{formattedAddress.line2}</div>}
          {formattedAddress?.line3 && <div>{formattedAddress.line3}</div>}
          {formattedAddress?.line4 && <div>{formattedAddress.line4}</div>}
        </ListRow>
        <ListRow label="Postcode">
          {formattedAddress?.postCode && formatUKPostcode(formattedAddress.postCode)}
        </ListRow>
        <ListRow label="Homeowner">{booleanAnswer(policyholder.isHomeOwner)}</ListRow>
        <ListRow label="Vehicles at home">
          {policyholder.numberOfVehiclesInHousehold}
        </ListRow>
      </List>
    </>
  );
}
