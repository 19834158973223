import { Button } from '@mui/material';

type QuoteCalculationClearCalculationActionProps = {
  onClearCalculation: () => void;
};

export function QuoteCalculationClearCalculationAction({
  onClearCalculation,
}: QuoteCalculationClearCalculationActionProps) {
  return (
    <Button variant="outlined" color="inherit" onClick={onClearCalculation}>
      Clear calculation
    </Button>
  );
}
