import { Box, Button, Checkbox, Divider, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { DocumentItem } from '../DocumentItem';
import { SendDocumentsModal } from '../SendDocumentsModal';
import { Document } from '@/models/Documents';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { useUser } from '@/hooks/useUser';
import { toKebabCase } from '@/helpers/utils/textHelpers';

type DocumentListProps = {
  documents: Document[];
};

const getDocumentTestId = (document: Document) => (elementName: string) =>
  `documents_list-item[${toKebabCase(document.description)}]_${elementName}`;

export function DocumentList({ documents }: DocumentListProps) {
  const { hasPermission } = useUser();
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const handleSelectDocument = (document: Document) => {
    setSelectedDocuments(currentSelected =>
      currentSelected.includes(document)
        ? currentSelected.filter(({ url }) => url !== document.url)
        : [...currentSelected, document],
    );
  };

  const isDocumentSelected = (document: Document) => selectedDocuments.includes(document);

  const resetSelectedDocuments = () => {
    setSelectedDocuments([]);
  };

  const togglePopup = () => setShouldShowPopup(!shouldShowPopup);

  return (
    <>
      {shouldShowPopup && (
        <SendDocumentsModal
          documents={selectedDocuments}
          onClose={togglePopup}
          onSuccess={() => resetSelectedDocuments()}
        />
      )}
      <StyledDrawerForm>
        <Box>
          {documents.map((document, index) => (
            <Box key={index}>
              <Grid container py={5} px={4} mt={1}>
                {hasPermission('sendbypost:support') && (
                  <Grid item width={42}>
                    <Checkbox
                      inputProps={{ 'aria-label': document.description }}
                      aria-checked={isDocumentSelected(document)}
                      checked={isDocumentSelected(document)}
                      onChange={() => handleSelectDocument(document)}
                      data-testid={getDocumentTestId(document)('checkbox')}
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                )}
                <Grid item xs={11} px={2}>
                  <Stack direction="column" spacing={6}>
                    <DocumentItem
                      document={document}
                      testId={getDocumentTestId(document)('link')}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {documents.length !== index + 1 && <Divider sx={{ mx: 6 }} />}
            </Box>
          ))}
        </Box>
        {hasPermission('sendbypost:support') && (
          <StyledButtonsFooter sticky>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              disabled={!selectedDocuments.length}
              data-testid="send-selected"
              onClick={togglePopup}
            >
              Send selected
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disabled={!selectedDocuments.length}
              data-testid="reset-form"
              onClick={resetSelectedDocuments}
            >
              Reset form
            </Button>
          </StyledButtonsFooter>
        )}
      </StyledDrawerForm>
    </>
  );
}
