import { RefObject, useCallback, useEffect, useState } from 'react';
import {
  AdyenCheckout,
  CustomCard,
  ICore,
  CustomCardConfiguration,
  CoreConfiguration,
} from '@adyen/adyen-web';
import {
  FieldValues,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useTheme } from '@mui/material';
import { useAdyenCheckoutContext } from './useAdyenCheckoutContext';
import { env } from '@/config/env';

type AdyenCore = ICore;

type UseAdyenCheckoutProps<T extends FieldValues> = {
  containerRef: RefObject<HTMLElement>;
  setValue: UseFormSetValue<T>;
  setError: UseFormSetError<T>;
  clearErrors: UseFormClearErrors<T>;
};

const getIsAdyenFocusEventObject = (
  event: Record<never, unknown>,
): event is Record<'focus', boolean> => Object.hasOwn(event, 'focus');

function useAdyenStyles() {
  const theme = useTheme();

  return {
    base: {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.fontSize}px`,
      fontWeight: '400',
      letterSpacing: theme.typography.body2.letterSpacing?.toString(),
    },
    error: {
      color: theme.palette.error.main,
    },
    placeholder: {
      color: theme.palette.text.primary,
    },
    validated: {
      color: theme.palette.text.primary,
    },
  };
}

export function useAdyenCheckout<T extends FieldValues>({
  containerRef,
  setValue,
  setError,
  clearErrors,
}: UseAdyenCheckoutProps<T>) {
  const { setFocusedField } = useAdyenCheckoutContext();
  const styles = useAdyenStyles();
  const [hasConfigured, setHasConfigured] = useState(false);
  const [isError, setIsError] = useState(false);
  const [checkout, setCheckout] = useState<AdyenCore>();
  const getAdyenCheckout = useCallback(async () => {
    const data = await AdyenCheckout({
      locale: 'en_GB',
      environment: env.config.ADYEN_ENVIRONMENT as CoreConfiguration['environment'],
      clientKey: env.config.ADYEN_CLIENT_KEY,
      countryCode: 'GB',
      translations: {
        'en-GB': {
          'creditCard.numberField.placeholder': 'Enter 16 digits',
        },
      },
    });

    setCheckout(data);
  }, [setCheckout]);
  const [customCard, setCustomCard] = useState<CustomCard | null>(null);

  const handleChange: CustomCardConfiguration['onChange'] = ({
    data: {
      paymentMethod: { checkoutAttemptId, type, brand, ...fields },
    },
    errors = {},
  }) => {
    Object.entries(errors).forEach(error => {
      const [key, value] = error;

      if (!value) {
        clearErrors(key as Path<T>);
        return;
      }

      setError(key as Path<T>, { message: value.errorI18n });
    });

    Object.entries(fields).forEach(([key, value]) =>
      setValue(key as Path<T>, value as PathValue<T, Path<T>>, {
        shouldDirty: true,
        shouldTouch: true,
      }),
    );
  };

  const onChange = useCallback(handleChange, []);

  const handleFocus: CustomCardConfiguration['onFocus'] = event => {
    const isAdyenFocusEventObject = getIsAdyenFocusEventObject(event);

    if (!isAdyenFocusEventObject || (isAdyenFocusEventObject && !event.focus)) {
      setFocusedField('');
      return;
    }

    setFocusedField(event.fieldType);
    return;
  };

  const onFocus = useCallback(handleFocus, [setFocusedField]);

  const onConfigSuccess = () => {
    setHasConfigured(true);
  };

  useEffect(() => {
    if (checkout) return;

    getAdyenCheckout();
  }, [checkout, getAdyenCheckout]);

  useEffect(() => {
    if (!checkout) return;
    setCustomCard(
      new CustomCard(checkout, {
        type: 'card',
        brands: ['mc', 'visa'],
        onFocus,
        onChange,
        onConfigSuccess,
        styles,
      }),
    );
  }, [checkout]);

  useEffect(() => {
    if (!customCard || !containerRef.current) return;

    try {
      customCard.mount(containerRef.current);
    } catch {
      setIsError(true);
    }
  }, [customCard, setHasConfigured, containerRef.current]);

  return {
    data: customCard,
    isPending: !hasConfigured,
    isError,
  };
}
