import { Stack, Button, Alert, List, Typography } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAdyenCheckout } from '../hooks/useAdyenCheckout';
import { useCardPaymentForm, CardPaymentFormSchema } from '../hooks/useCardPaymentForm';
import { useSelectedPreviouslyUsedPaymentMethod } from '../hooks/useSelectedPreviouslyUsedPaymentMethod';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { FormAdyenTextField } from '@/components/ui/forms/FormAdyenTextField';
import { FormAdyenDateField } from '@/components/ui/forms/FormAdyenDateField';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';
import {
  mapStoredPaymentsQueryToSelectOptions,
  useStoredPaymentsQuery,
} from '@/hooks/queries/useStoredPaymentsQuery';
import { renderSelectOptionsByOptionsCodes } from '@/helpers/utils/formHelpers';
import { ListRow } from '@/components/ui/lists/ListRow';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { StyledGrayBox, StyledGrayBoxItem } from '@/components/ui/styles/StyledBox';
import { FormattedPrice } from '@/components/ui/presentation/FormattedPrice';
import { Modal } from '@/components/ui/modals/Modal';
import { FormCheckbox } from '@/components/ui/forms/FormCheckbox';

export type CardPaymentModalContentProps = {
  isPending?: boolean;
  isError?: boolean;
  amount: number;
  canBypassPayment?: boolean;
  onSubmit: (values: CardPaymentFormSchema) => void;
  onClear: () => void;
  onBypassPayment: () => void;
};

export function CardPaymentModalContent({
  isPending,
  isError,
  amount,
  canBypassPayment,
  onSubmit,
  onClear,
  onBypassPayment,
}: CardPaymentModalContentProps) {
  const formName = 'card-payment-form';
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);

  const form = useCardPaymentForm();
  const {
    formState: { isDirty, errors },
  } = form;
  const shopperReference = policy?.policyholder.customerId ?? '';
  const { data: storedPayments = [], isFetching: isStoredPaymentsMutationFetching } =
    useStoredPaymentsQuery(
      {
        shopperReference,
      },
      {
        select: mapStoredPaymentsQueryToSelectOptions,
      },
    );
  const shouldShowPreviouslyUsedPaymentMethodField = storedPayments.length > 0;
  const { setValue, setError, clearErrors, handleSubmit, watch } = form;
  const previouslyUsedPaymentMethodField = watch('previouslyUsedPaymentMethod');
  const selectedPreviouslyUsedPaymentMethod = useSelectedPreviouslyUsedPaymentMethod({
    storedPaymentMethodId: previouslyUsedPaymentMethodField,
    shopperReference,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const { isPending: isAdyenCheckoutPending, isError: isAdyenCheckoutError } =
    useAdyenCheckout<CardPaymentFormSchema>({
      containerRef,
      setValue,
      setError,
      clearErrors,
    });
  const shouldShowLoader =
    isAdyenCheckoutPending || isStoredPaymentsMutationFetching || isPending;
  const shouldShowError = isDirty && (isAdyenCheckoutError || isError);

  useEffect(() => {
    if (previouslyUsedPaymentMethodField === 'clear') {
      onClear();
    }
  }, [previouslyUsedPaymentMethodField]);

  return (
    <>
      <Modal.Content>
        <form
          id={formName}
          style={{ position: 'relative' }}
          onSubmit={event => {
            event.stopPropagation();
            handleSubmit(onSubmit)(event);
          }}
        >
          {shouldShowError && (
            <Alert severity="error" sx={{ mb: 6 }}>
              {isError ? 'Payment has been unsuccessful try again.' : GENERAL_ERROR_MSG}
            </Alert>
          )}
          <Stack
            id="customCard-container"
            ref={containerRef}
            direction="column"
            gap={6}
            visibility={shouldShowLoader ? 'hidden' : 'visible'}
          >
            <FormProvider {...form}>
              {shouldShowPreviouslyUsedPaymentMethodField ? (
                <FormTextField
                  name="previouslyUsedPaymentMethod"
                  select
                  sx={{ maxWidth: '65%' }}
                  label="Choose a previously used card"
                >
                  {renderSelectOptionsByOptionsCodes([
                    ...storedPayments,
                    ...(previouslyUsedPaymentMethodField
                      ? [{ code: 'clear', description: 'Enter details manually' }]
                      : []),
                  ])}
                </FormTextField>
              ) : null}
              {selectedPreviouslyUsedPaymentMethod ? (
                <List>
                  <ListRow label="Card holder name">
                    {selectedPreviouslyUsedPaymentMethod.holderName}
                  </ListRow>
                  <ListRow label="Card number">
                    **** **** **** {selectedPreviouslyUsedPaymentMethod.lastFour}
                  </ListRow>
                  <ListRow label="Expiry date">
                    {selectedPreviouslyUsedPaymentMethod.expiryMonth} /
                    {selectedPreviouslyUsedPaymentMethod.expiryYear}
                  </ListRow>
                </List>
              ) : (
                <>
                  <FormAdyenTextField
                    name="encryptedCardNumber"
                    label="Card number"
                    sx={{ maxWidth: '65%' }}
                  />
                  <FormTextField
                    label="Name on card"
                    name="cardholderName"
                    sx={{ maxWidth: '65%' }}
                    displayStyle="basic"
                  />
                  <FormAdyenDateField
                    monthFieldName="encryptedExpiryMonth"
                    yearFieldName="encryptedExpiryYear"
                    sx={{ maxWidth: '32%' }}
                  />
                  <FormAdyenTextField
                    name="encryptedSecurityCode"
                    label="Security code"
                    sx={{ maxWidth: '16%' }}
                  />
                </>
              )}
              <FormCheckbox
                label="I confirm I have permission from the customer to use these card details"
                name="hasPermissionToUseCardDetails"
              />
            </FormProvider>
            <StyledGrayBox>
              <StyledGrayBoxItem>
                <Typography variant="subtitle1">One-off payment</Typography>
                <FormattedPrice price={amount} />
              </StyledGrayBoxItem>
            </StyledGrayBox>
            {canBypassPayment && (
              <Button sx={{ alignSelf: 'flex-start' }} onClick={onBypassPayment}>
                Continue without payment
              </Button>
            )}
          </Stack>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          type="submit"
          form={formName}
          variant="contained"
          disabled={Object.keys(errors).length > 0}
          loading={shouldShowLoader}
        >
          Process payment
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={onClear}>
          Clear form
        </Button>
      </Modal.Actions>
    </>
  );
}
