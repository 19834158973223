import { TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { CancelPolicyForm } from './components/CancelPolicyForm';
import { PolicyAction } from './actionsTypes';
import { CustomerLetter } from './components/CustomerLetter/CustomerLetter';
import { ContactUsEmail } from './components/ContactUsEmail/ContactUsEmail';
import { ActionsContext } from './hooks/useActionsContext';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { getIsPolicyInactive } from '@/helpers/data/policyHelpers';

export function Actions() {
  const parentId = useContext(PolicyIdContext);
  const {
    isPending: isPolicyPending,
    isError: isPolicyError,
    data: policy,
  } = useMotorPolicyQuery(parentId);
  const [isPolicyActionVisible, setIsPolicyActionVisible] = useState(true);
  const [selectedAction, setSelectedAction] = useState<PolicyAction | ''>('');
  const isPolicyInactive = !!policy && getIsPolicyInactive(policy);
  const policyActionMenuItems = renderSelectOptionsByEnum(PolicyAction).filter(
    item => !(isPolicyInactive && item.key === PolicyAction.CancelPolicy),
  );

  if (isPolicyError) return <DrawerError />;
  if (isPolicyPending) return <DrawerLoading />;

  const renderFormFields = () => {
    switch (selectedAction) {
      case PolicyAction.CancelPolicy:
        return <CancelPolicyForm policy={policy} />;
      case PolicyAction.GenerateCustomerLetter:
        return <CustomerLetter />;
      case PolicyAction.GenerateContactUsEmail:
        return <ContactUsEmail />;
      default:
        return null;
    }
  };

  return (
    <ActionsContext.Provider value={{ setIsPolicyActionVisible }}>
      {isPolicyActionVisible && (
        <TextField
          select
          id="actions_action-type-selectbox"
          label="Policy action"
          sx={{ ...commonSx, ...maxWidthSx, mx: 6, mt: 6 }}
          value={selectedAction}
          onChange={e => setSelectedAction(e.target.value as PolicyAction)}
        >
          {policyActionMenuItems}
        </TextField>
      )}
      {renderFormFields()}
    </ActionsContext.Provider>
  );
}
