import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';
import { useState } from 'react';
import { NewMtaCarDetailsRegQueryField } from './NewMtaCarDetailsRegQueryField';
import { NewMtaCarDetailsList } from './NewMtaCarDetailsList';
import { NewMtaCarDetailsForm } from './NewMtaCarDetailsForm';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { useVehicleRegQuery } from '@/features/NewMta/components/NewMtaCar/hooks/useVehicleRegQuery';
import { LoaderWrapper } from '@/components/ui/loaders/LoaderWrapper';
import { changedCarEmptyFormFields } from '@/features/NewMta/helpers/mapPolicyToNewMtaForm/mapPolicyToNewMtaCarForm';
import { FormCheckbox } from '@/components/ui/forms/FormCheckbox';

export function NewMtaCarDetailsChangeCar() {
  const { setValue, watch } = useFormContext<NewMtaFormSchema>();
  const {
    isQueryPending,
    isQueryError,
    areCarDetailsUpdated,
    validateAndUpdateQueryReg,
  } = useVehicleRegQuery();
  const [isCarDetailsFormOpen, setIsCarDetailsFormOpen] = useState(false);
  const policy = watch('_policy');
  const carFormValues = watch('car');
  const listTitle = areCarDetailsUpdated ? 'New details' : 'Current details';

  const openCarDetailsForm = () => setIsCarDetailsFormOpen(true);
  const closeCarDetailsForm = () => setIsCarDetailsFormOpen(false);

  const handleCarDetailsFormSubmit = (details: NewMtaFormSchema['car']['details']) => {
    setValue('car', {
      ...carFormValues,
      ...changedCarEmptyFormFields,
      details,
    });
    closeCarDetailsForm();
  };

  if (isCarDetailsFormOpen) {
    return (
      <NewMtaCarDetailsForm
        onSubmitSuccess={handleCarDetailsFormSubmit}
        onCancel={closeCarDetailsForm}
      />
    );
  }

  return (
    <>
      <NewMtaCarDetailsRegQueryField
        inputLabel="Registration"
        buttonText="Find car"
        isError={isQueryError}
        isPending={isQueryPending}
        onConfirm={validateAndUpdateQueryReg}
      />
      {carFormValues._queryRegistration !== policy.vehicle.registrationNumber && (
        <FormCheckbox
          label="Have verified that the car has not been impounded"
          name="car.hasVerifiedCarNotImpounded"
          testId="new-mta_has-verified-car-not-impounded-checkbox"
        />
      )}
      {isQueryError ? (
        <Button
          size="small"
          variant="contained"
          sx={{ mt: 3 }}
          onClick={openCarDetailsForm}
        >
          Enter car details manually
        </Button>
      ) : (
        <LoaderWrapper isLoading={isQueryPending} repeat={3}>
          <NewMtaCarDetailsList title={listTitle} carDetails={carFormValues.details} />
        </LoaderWrapper>
      )}
    </>
  );
}
