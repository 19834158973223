import { PropsWithChildren } from 'react';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

export function QuoteCalculationPrimaryActions({ children }: PropsWithChildren) {
  return (
    <StyledButtonsFooter sticky sx={{ mx: -6, mb: -6, mt: 'auto' }}>
      {children}
    </StyledButtonsFooter>
  );
}
