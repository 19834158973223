import { Button } from '@mui/material';
import { useState } from 'react';
import { useQuoteCalculationContext } from '../hooks/useQuoteCalculationContext';
import { QuoteCalculationUpdateFeesPremiumModal } from './QuoteCalculationUpdateFeesPremiumModal';
import { useChangeFeesPremium } from './QuoteCalculationUpdateFeesPremiumModal/hooks/useChangeFeesPremium';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';

export function QuoteCalculationUpdateFeesPremiumAction() {
  const { fee, netPremium } = useQuoteCalculationContext();
  const [originalFee] = useState(fee);
  const [originalPremium] = useState(netPremium);

  const { setAlert } = useSnackbarAlert({ closeOnUnmount: false });
  const [isModalOpen, setModalOpen] = useState(false);
  const { hasChangeFeesPremiumPermissions, changeFeesPremiumTitle } =
    useChangeFeesPremium();

  const handleSuccess = () => {
    setAlert({ message: 'Fees have been updated', severity: 'success' });
    setModalOpen(false);
  };

  if (!hasChangeFeesPremiumPermissions) return null;

  return (
    <>
      {isModalOpen && (
        <QuoteCalculationUpdateFeesPremiumModal
          defaultValues={{
            fee: String(fee.total?.amount),
            premium: String(netPremium),
          }}
          originalFee={originalFee.total?.amount ?? 0}
          originalPremium={originalPremium ?? 0}
          onSuccess={handleSuccess}
          onClose={() => setModalOpen(false)}
        />
      )}
      <Button size="small" onClick={() => setModalOpen(true)}>
        {changeFeesPremiumTitle}
      </Button>
    </>
  );
}
