import { NotesService } from './services/NotesService';
import { BaseAPI } from '../core/BaseAPI';
import { env } from '@/config/env';

export class NoteAPI extends BaseAPI {
  readonly notes: NotesService;

  constructor() {
    super({ BASE: env.config.NOTE_API_URL });

    this.notes = new NotesService(this.request);
  }
}
