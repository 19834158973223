import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { VulnerabilitiesFormSchema } from './useVulnerabilitiesForm';
import { YesNo } from '@/models/YesNo';
import { VulnerabilityCategory } from '@/api/support';
import { useSetValue } from '@/hooks/useSetValue';

export function useVulnerabilityFormOperations(
  form: UseFormReturn<VulnerabilitiesFormSchema>,
) {
  const {
    setValue,
    resetField,
    watch,
    formState: { isDirty, errors },
    trigger,
  } = form;

  const isEditMode = Boolean(watch('_id'));
  const isTemporary = watch('temporary') === YesNo.Yes;
  const isFollowUpRequired = watch('followUpRequired') === YesNo.Yes;
  const followUpDateField = watch('followUpDate');
  const categoryField = watch('category');

  const handleCategoryChange = (category: VulnerabilityCategory) => {
    setValue('category', category);
    resetField('type');
    trigger('category');
  };

  const revalidateFollowUpDateWhenNotRequired = () => {
    if (!isFollowUpRequired && errors.followUpDate) {
      trigger('followUpDate');
    }
  };

  useSetValue<VulnerabilitiesFormSchema>({
    name: 'followUpDate',
    value: null,
    shouldSetValue: !!followUpDateField && !isFollowUpRequired && !isTemporary,
    setValue,
  });

  useSetValue<VulnerabilitiesFormSchema>({
    name: 'followUpRequired',
    value: YesNo.No,
    shouldSetValue: isDirty && isEditMode && !followUpDateField,
    setValue,
  });

  useEffect(() => {
    revalidateFollowUpDateWhenNotRequired();
  }, [isFollowUpRequired]);

  return {
    isEditMode,
    isFollowUpRequired,
    isTemporary,
    categoryField,
    handleCategoryChange,
  };
}
