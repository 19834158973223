import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Button, Divider, List, Stack, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import { Modal } from '@/components/ui/modals/Modal';
import { Document } from '@/models/Documents';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useDocumentsByPostMutation } from '@/hooks/mutations/useDocumentsByPostMutation';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { useSetAlertError } from '@/hooks/useSetAlertError';

type SendDocumentsModalProps = {
  documents: Document[];
  onSuccess: () => void;
  onClose: () => void;
};

const StyledDocumentList = styled(List)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  gap: ${({ theme }) => theme.spacing(6)};
`;

const StyledDocumentListItem = styled('li')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const StyledAddressLineText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export function SendDocumentsModal({
  documents,
  onSuccess,
  onClose,
}: SendDocumentsModalProps) {
  const { setAlert } = useSnackbarAlert({ closeOnUnmount: false });
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const handleSuccess = () => {
    setAlert({
      message: "Documents will be sent to policyholder's address",
      severity: 'success',
    });
    onSuccess();
    onClose();
  };
  const {
    mutate: sendDocumentsByPost,
    isPending,
    isError,
  } = useDocumentsByPostMutation({
    onSuccess: handleSuccess,
  });

  const onSend = () =>
    sendDocumentsByPost({
      documents,
      policyholderFirstName: policy?.policyholder.firstName,
      policyholderLastName: policy?.policyholder.lastName,
      formattedAddress: policy?.policyholder.address?.formattedAddress,
    });

  useSetAlertError(isError);

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Send documents</Modal.Header>
      <Modal.Content>
        <StyledDocumentList>
          {documents.map(({ url, description }) => (
            <StyledDocumentListItem key={url}>
              <InsertDriveFileOutlinedIcon />
              <Typography variant="subtitle1">{description}</Typography>
            </StyledDocumentListItem>
          ))}
        </StyledDocumentList>
        <Divider />
        <Stack justifyContent="space-between" py={6}>
          <Typography variant="body2" noWrap width="50%">
            Documents will be sent to
          </Typography>
          <List disablePadding>
            {Object.values(policy?.policyholder.address?.formattedAddress ?? {}).map(
              (line, index) => (
                <li key={index}>
                  <StyledAddressLineText variant="body2" noWrap>
                    {line}
                  </StyledAddressLineText>
                </li>
              ),
            )}
          </List>
        </Stack>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          type="button"
          variant="contained"
          color="secondary"
          data-testid="send-selected"
          loading={isPending}
          onClick={onSend}
        >
          Send documents
        </LoadingButton>
        <Button
          variant="outlined"
          color="inherit"
          data-testid="reset-form"
          disabled={isPending}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
