import { Navigate, Outlet, createBrowserRouter } from 'react-router';
import { Root } from '@/Root';
import { RoutePath } from '@/models/RoutePath';
import { Search } from '@/features/Search';
import { Loading } from '@/components/ui/loaders/Loading';
import { AuthRoute } from '@/features/Auth/AuthRoute';
import { ErrorBoundary } from '@/components/boundaries/ErrorBoundary';
import { Permission } from '@/models/Permission';
import { useUser } from '@/hooks/useUser';
import { AuthEventsHandler } from '@/features/Auth/AuthEventsHandler';
import { PublicRoot } from '@/PublicRoot';

function ProtectedRoute({ permissions }: { permissions: Permission[] }) {
  const { hasPermissions } = useUser();
  return hasPermissions(permissions) ? <Outlet /> : <Navigate to="/" replace />;
}

export const router = createBrowserRouter([
  {
    path: RoutePath.Root,
    element: (
      <AuthRoute>
        <AuthEventsHandler>
          <Root />
        </AuthEventsHandler>
      </AuthRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={RoutePath.Search} />,
      },
      {
        path: '/logout',
        element: <Navigate to="/" />,
      },
      {
        path: RoutePath.Search,
        element: <Search />,
        children: [
          { index: true, element: <Navigate to={RoutePath.Policies} /> },
          {
            path: RoutePath.Policies,
            lazy: async () => ({
              Component: (await import('@/features/Policies')).Policies,
            }),
          },
          {
            path: RoutePath.Quotes,
            lazy: async () => ({
              Component: (await import('@/features/Quotes')).Quotes,
            }),
          },
        ],
      },
      {
        path: RoutePath.AdminReminders,
        element: <ProtectedRoute permissions={['admin:reminders']} />,
        children: [
          {
            path: '',
            lazy: async () => ({
              Component: (await import('@/features/AdminReminders')).AdminReminders,
            }),
          },
        ],
      },
      {
        path: RoutePath.Users,
        element: <ProtectedRoute permissions={['manageusers:support']} />,
        children: [
          {
            path: '',
            lazy: async () => ({
              Component: (await import('@/features/Users')).Users,
            }),
          },
        ],
      },
    ],
  },
  {
    path: '',
    element: <PublicRoot />,
    children: [
      {
        path: 'auth/sign-up/verify',
        lazy: async () => ({
          Component: (await import('@/features/ConfirmAccount')).ConfirmAccount,
        }),
      },
      {
        path: 'auth/set-password',
        lazy: async () => ({
          Component: (await import('@/features/SetPassword')).SetPassword,
        }),
      },
      {
        path: 'auth/forgot-password',
        lazy: async () => ({
          Component: (await import('@/features/ForgotPassword')).ForgotPassword,
        }),
      },
    ],
  },
  {
    path: 'login/redirect',
    element: <Loading />,
  },
]);
